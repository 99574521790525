import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useAuth } from "@frontend/ui/auth";
import { Loader } from "@frontend/ui/loader";
import { RouteParams, RoutePath } from "../../../config/routes";
import { replaceHomePath } from "./utils";
import { AlertsTab, AvsTab, NodeOperatorsTab, OverviewTab, WithdrawalsTab } from "../tabs";
import { renderAvsPageLayout, renderNodeOperatorPageLayout, renderTabsLayout } from "./layout/layout";
import { ChaosApolloProvider } from "../../../utils/graphql";
import { FeatureFlagsProvider } from "../../../utils/feature-flags";
import { AvsDetails, ChainOverviewDetails, NodeOperatorsDetails } from "../pages";
import { HoldersTab } from "../tabs/holders";
import { ETHERFI_APPROVED_ROLE } from "../../../utils/consts";
import { TopHolderDetails } from "../tabs/holder-details/top-holder-details";
import { OnChainLiquidity } from "../tabs/on-chain-liquidity/on-chain-liquidity";
import { DepositDrillDown, WithdrawalDrillDown } from "../tabs/withdrawals/components";

const RiskMonitoringStakingRoutes = () => {
  const { clientName } = useParams();
  const defaultRoute = RoutePath.Risk.Overview.replace(RouteParams.ClientName, clientName!);
  const auth = useAuth();

  return (
    <ChaosApolloProvider product="staking">
      <FeatureFlagsProvider>
        <Routes>
          <Route index element={<Navigate to={defaultRoute} replace />} />
          <Route path={replaceHomePath(RoutePath.Risk.Overview)} element={renderTabsLayout(<OverviewTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.Withdrawals)} element={renderTabsLayout(<WithdrawalsTab />)} />
          {auth.hasRole(ETHERFI_APPROVED_ROLE) && (
            <Route
              path={replaceHomePath(RoutePath.Risk.WithdrawalDrillDown)}
              element={renderTabsLayout(<WithdrawalDrillDown />)}
            />
          )}
          {auth.hasRole(ETHERFI_APPROVED_ROLE) && (
            <Route
              path={replaceHomePath(RoutePath.Risk.DepositDrillDown)}
              element={renderTabsLayout(<DepositDrillDown />)}
            />
          )}
          <Route path={replaceHomePath(RoutePath.Risk.Avs)} element={renderTabsLayout(<AvsTab />)} />
          <Route path={replaceHomePath(RoutePath.Risk.AvsDetails)} element={renderAvsPageLayout(<AvsDetails />)} />
          <Route
            path={replaceHomePath(RoutePath.Risk.NodeOperators)}
            element={renderTabsLayout(<NodeOperatorsTab />)}
          />
          <Route
            path={replaceHomePath(RoutePath.Risk.NodeOperatorDetails)}
            element={renderNodeOperatorPageLayout(<NodeOperatorsDetails />)}
          />
          {auth.hasRole(ETHERFI_APPROVED_ROLE) && (
            <>
              <Route path={replaceHomePath(RoutePath.Risk.Holders)} element={renderTabsLayout(<HoldersTab />)} />
              <Route
                path={replaceHomePath(RoutePath.Risk.TopHolderDetails)}
                element={renderTabsLayout(<TopHolderDetails />)}
              />
              <Route
                path={replaceHomePath(RoutePath.Risk.OnChainLiquidity)}
                element={renderTabsLayout(<OnChainLiquidity />)}
              />
              <Route
                path={replaceHomePath(RoutePath.Risk.OnChainLiquidityDetails)}
                element={renderTabsLayout(<ChainOverviewDetails />)}
              />
            </>
          )}
          <Route path={replaceHomePath(RoutePath.Risk.Alerts)} element={renderTabsLayout(<AlertsTab />)} />
          <Route path="*" element={auth.isRefreshing ? <Loader /> : <Navigate to={defaultRoute} replace />} />
        </Routes>
      </FeatureFlagsProvider>
    </ChaosApolloProvider>
  );
};

export default RiskMonitoringStakingRoutes;
