import { TimeSpan } from "@frontend/ui/echarts/types";
import { Box, Grid } from "@frontend/ui";
import { useState } from "react";
import { TimeSpanPicker } from "../../../../ccar-lending-page/components/time-span-picker";
import { LiquidityByChain1DayChange } from "./liquidity-by-chain-change";
import { TvlByChainOverTime } from "./tvl-by-chain-over-time";
import { ExitLiquidityByTokenChart } from "./exit-liquidity-by-token-chart";
import { UtilizationMovingAverageChart } from "./utilization-moving-average-chart";

export const ChartsGrid = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Month);
  return (
    <Box>
      <Box
        sx={{
          float: "right",
        }}
      >
        <TimeSpanPicker
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
          timeSpanOptions={[TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year]}
        />
      </Box>
      <Grid container>
        <TvlByChainOverTime timeSpan={timeSpan} />
        <LiquidityByChain1DayChange />
        <ExitLiquidityByTokenChart timeSpan={timeSpan} />
        <UtilizationMovingAverageChart timeSpan={timeSpan} />
      </Grid>
    </Box>
  );
};
