import { Box, Paper } from "@frontend/ui";
import { useRef, useState, useEffect } from "react";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { getChartSeries, getSpotPrice } from "./utils";
import { depthChartTooltipFormatter } from "./tooltip-formatter";
import { Order } from "./types";
import ChartWidget from "../chart-widget/chart-widget";

type Props = {
  tokenSymbol: string;
  orderBook: Order[];
  cryptoCurrency?: string;
  loading?: boolean;
};

const DEFAULT_CHART_PRICE_GAP_PERCENTAGE = 0.015;
const MIN_CHART_PRICE_GAP_PERCENTAGE = 0.002;
const CHART_PRICE_GAP_INCREMENT_PERCENTAGE = 0.001;

export const OrderBookDepth = ({ tokenSymbol, orderBook, cryptoCurrency, loading }: Props) => {
  const [priceRange, setPriceRange] = useState<{ start: number; end: number }>();
  const chartContainerRef = useRef<HTMLDivElement>();
  const spotPrice = getSpotPrice(orderBook);
  const seriesData = getChartSeries(orderBook);
  const maxTotalSize = seriesData
    .map((s) => s.data)
    .flat()
    .reduce((max, datum) => {
      const [price, totalSize] = datum;
      if (price >= (priceRange?.start || 0) && price <= (priceRange?.end || 0)) {
        return Math.max(max, totalSize);
      }
      return max;
    }, 0);

  useEffect(() => {
    if (spotPrice) {
      setPriceRange({
        start: spotPrice * (1 - DEFAULT_CHART_PRICE_GAP_PERCENTAGE),
        end: spotPrice * (1 + DEFAULT_CHART_PRICE_GAP_PERCENTAGE),
      });
    }
  }, [spotPrice]);

  useEffect(() => {
    if (chartContainerRef.current && spotPrice) {
      chartContainerRef.current.addEventListener("wheel", (e) => {
        e.preventDefault();
        const isZoomIn = e.deltaY > 0;
        const minStart = spotPrice * (1 - MIN_CHART_PRICE_GAP_PERCENTAGE);
        const minEnd = spotPrice * (1 + MIN_CHART_PRICE_GAP_PERCENTAGE);

        setPriceRange((prev) => {
          if (!prev) return prev;

          const { start, end } = prev;
          const reachedZoomLimit = start > minStart && end < minEnd && isZoomIn;
          if (reachedZoomLimit) return { start, end };

          return {
            start: start + spotPrice * CHART_PRICE_GAP_INCREMENT_PERCENTAGE * (isZoomIn ? 1 : -1),
            end: end + spotPrice * CHART_PRICE_GAP_INCREMENT_PERCENTAGE * (isZoomIn ? -1 : 1),
          };
        });
      });
    }
  }, [spotPrice]);

  return (
    <Paper variant="widget">
      <Box ref={chartContainerRef}>
        <ChartWidget
          series={seriesData}
          tooltipFormatter={depthChartTooltipFormatter(tokenSymbol)}
          type="line"
          title="Order Book Depth"
          grid={{
            right: 25,
          }}
          dataZoom={
            priceRange
              ? {
                  show: false,
                  filterMode: "none",
                  startValue: priceRange.start,
                  endValue: priceRange.end,
                }
              : undefined
          }
          xAxisOptions={{
            animation: false,
            type: "value",
            axisLabel: {
              formatter: (value: number) =>
                !cryptoCurrency ? formatAmount(value, { currency: "USD" }) : `${formatAmount(value)} ${cryptoCurrency}`,
              hideOverlap: false,
              fontSize: 12,
            },
            splitLine: {
              show: false,
            },
            min: "dataMin",
            max: "dataMax",
          }}
          yAxisOptions={{
            max: maxTotalSize,
          }}
          isLoading={loading}
        />
      </Box>
    </Paper>
  );
};
