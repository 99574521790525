import { CompositionOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { ChainDexDataPoint } from "../../../generated";

type DexDataField = "exitLiquidityInEth" | "exitPercentDeFi" | "liquidityInEth" | "utilization14dMovingAvg";

type DexBreakdownChartProps = {
  title: string;
  dataField: DexDataField;
  data?: ChainDexDataPoint[];
  isLoading: boolean;
  disableStacked?: boolean;
  currency?: string;
  cryptoCurrency?: string;
  isPercent?: boolean;
  showArea?: boolean;
  hideTotal?: boolean;
};

export const DexBreakdownChart = ({
  title,
  dataField,
  data,
  isLoading,
  showArea,
  currency,
  disableStacked,
  cryptoCurrency,
  isPercent,
  hideTotal,
}: DexBreakdownChartProps) => {
  const chartData = (data ?? []).reduce<Record<string, NumberChartSeries>>((acc, point) => {
    const { project, timestamp } = point;
    const series = acc[project];
    const newPoint = [timestamp, point[dataField]] as [number, number];
    const prevData = series?.data ?? [];
    const newSeries: NumberChartSeries = {
      label: project,
      type: "line",
      showArea,
      data: [...prevData, newPoint],
    };
    return { ...acc, [project]: newSeries };
  }, {});

  return (
    <CompositionOverTimeChart
      title={title}
      series={Object.values(chartData)}
      isLoading={isLoading}
      disableStacked={disableStacked}
      cryptoCurrency={cryptoCurrency}
      isPercent={isPercent}
      currency={currency}
      hideTotal={hideTotal}
      seriesSorter={(seriesA: NumberChartSeries, seriesB: NumberChartSeries) =>
        seriesA.label?.localeCompare(seriesB?.label ?? "") ?? 0
      }
    />
  );
};
