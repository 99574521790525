import { PageHeader } from "@frontend/ui/page-header";
import { Box } from "@frontend/ui";
import AlertsTable from "../../../../components/alerts-table/alerts";

export const AlertsTab = () => (
  <Box>
    <PageHeader showBackButton={false} pageTitle="Alerts" />
    <AlertsTable
      notificationsChannels={[
        {
          channel: "telegram",
          link: "https://t.me/+W2uSlczgHYxmOGFh",
        },
      ]}
    />
  </Box>
);
