import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, CompositionOverTimeChart, CryptoIcon, CustomReactSelect, CustomSwitch, Grid, Paper } from "@frontend/ui";
import { MarketData, useWalletHistoryQuery, useWalletLiquidationRangesQuery } from "../../../../generated";
import { LiquidationRange } from "./liquidation-range";

const WalletHistory = ({ markets }: { markets: MarketData[] }) => {
  let { address } = useParams<{ address: string }>();
  address = address!.toLowerCase();
  const [selectedMarketName, setSelectedMarketName] = useState(markets[0].name);
  const [showLossPercent, setShowLossPercent] = useState(false);
  const { data, loading } = useWalletHistoryQuery({
    variables: { marketSymbol: selectedMarketName, address },
  });
  const { loading: loadingLiquidationRanges, data: liquidationRanges } = useWalletLiquidationRangesQuery({
    variables: { address },
  });
  const liquidationRange = liquidationRanges?.walletLiquidationRanges.find(
    (r) => r.marketId === markets.find((m) => m.name === selectedMarketName)?.id,
  );
  const options = markets.map((m) => ({ value: m.id, label: m.name, cryptoIcon: m.name.toLowerCase() }));

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <CustomReactSelect
          options={options}
          onChange={(o) => setSelectedMarketName(markets.find((m) => m.id === o?.value)!.name)}
          isSearchable={false}
          value={options.find((o) => o.label === selectedMarketName)}
          sx={{ marginLeft: "auto", mr: 3 }}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <CompositionOverTimeChart
            title="crvUSD Collateral"
            series={[
              {
                data: data?.walletHistory.collateralStablecoin.map(({ time, value }) => [time, value]) || [],
                label: "crvUSD",
              },
            ]}
            isLoading={loading}
            currency=""
            percentToggle
            showLegend={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompositionOverTimeChart
            title={`${selectedMarketName} Collateral`}
            series={[
              {
                data: data?.walletHistory.collateralToken.map(({ time, value }) => [time, value]) || [],
                label: selectedMarketName,
              },
            ]}
            isLoading={loading}
            currency=""
            percentToggle
            showLegend={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompositionOverTimeChart
            title="Total Borrow"
            series={[
              {
                data: data?.walletHistory.borrow.map(({ time, value }) => [time, value]) || [],
                label: "Total Borrow",
              },
            ]}
            isLoading={loading}
            showLegend={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompositionOverTimeChart
            title={showLossPercent ? "% Lost" : "Loss Amount"}
            series={[
              {
                data:
                  (showLossPercent ? data?.walletHistory.lossPercent : data?.walletHistory.loss)?.map(
                    ({ time, value }) => [time, value],
                  ) || [],
                label: showLossPercent ? "% Lost" : "Loss Amount",
                type: "line",
              },
            ]}
            isLoading={loading}
            isPercent={showLossPercent}
            currency=""
            headerSuffix={
              <CustomSwitch
                onChange={() => setShowLossPercent(!showLossPercent)}
                checked={!showLossPercent}
                checkedLabel={<CryptoIcon icon={selectedMarketName.toLowerCase()} />}
                uncheckedLabel="%"
              />
            }
            showLegend={false}
          />
        </Grid>
        <Grid item xs={12}>
          <LiquidationRange range={liquidationRange} loading={loadingLiquidationRanges} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WalletHistory;
