import { TimeSpan } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart, Grid } from "@frontend/ui";
import { useDexTvlOverTimeQuery } from "../../../generated";

type Props = {
  timeSpan: TimeSpan;
};

export const TvlByChainOverTime = ({ timeSpan }: Props) => {
  const { data, loading } = useDexTvlOverTimeQuery({ variables: { timeSpan } });
  const { dexTvlOverTime } = data || {};

  const reduceByChain = (dexTvlOverTime || []).reduce(
    (acc, point) => {
      const { chain, timestamp, tvlInEth, exitLiquidityEth } = point;
      const { dexTvlByChainOverTimeMap, exitLiquidityByChainOverTimeMap } = acc;
      if (!dexTvlByChainOverTimeMap[chain]) {
        dexTvlByChainOverTimeMap[chain] = [];
      }
      if (!exitLiquidityByChainOverTimeMap[chain]) {
        exitLiquidityByChainOverTimeMap[chain] = [];
      }
      dexTvlByChainOverTimeMap[chain].push([timestamp, tvlInEth]);
      exitLiquidityByChainOverTimeMap[chain].push([timestamp, exitLiquidityEth]);
      return { dexTvlByChainOverTimeMap, exitLiquidityByChainOverTimeMap };
    },
    {
      dexTvlByChainOverTimeMap: {} as Record<string, [number, number][]>,
      exitLiquidityByChainOverTimeMap: {} as Record<string, [number, number][]>,
    },
  );

  const dexTvlByChainOverTimeSeries = Object.keys(reduceByChain.dexTvlByChainOverTimeMap).map((key) => ({
    label: key,
    data: reduceByChain.dexTvlByChainOverTimeMap[key],
  }));

  const exitLiquidityByChainOverTimeSeries = Object.keys(reduceByChain.exitLiquidityByChainOverTimeMap).map((key) => ({
    label: key,
    data: reduceByChain.exitLiquidityByChainOverTimeMap[key],
  }));

  return (
    <>
      <Grid item xs={12} lg={6}>
        <CompositionOverTimeChart
          title="weETH DEX TVL"
          series={dexTvlByChainOverTimeSeries}
          isLoading={loading}
          cryptoCurrency="ETH"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CompositionOverTimeChart
          title="Exit Liquidity TVL by Chain"
          series={exitLiquidityByChainOverTimeSeries}
          isLoading={loading}
          cryptoCurrency="ETH"
        />
      </Grid>
    </>
  );
};
