import { FC, useMemo } from "react";
import { Header } from "@frontend/types";
import { Box } from "../box";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { IconButton } from "../icon-button";
import { CustomIcon } from "../custom-icon";
import type { Filter } from "./table-filter";
import { formatDate, formatDuration } from "../utils/formatters";

interface TableAppliedFilterProps {
  filters: Record<number, Filter>;
  headers: Header[];
  removeFilter: (index: number) => void;
}

const rangeFilterValue = (value: string, header: Header) => `${header.prefix || ""}${value}${header.suffix || ""}`;

const formatNumber = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "decimal",
  }).format(value);

const getFilterValue = (filter: Filter, header: Header) => {
  switch (filter.type) {
    case "options":
      return filter.options
        .filter(({ isHidden }) => !isHidden)
        .map(({ name }) => name)
        .join(", ");
    case "range":
      return `${rangeFilterValue(
        filter.isDate
          ? formatDate(new Date(filter.range[0]))
          : filter.isDuration
            ? formatDuration({
                milliseconds: filter.range[0],
              })
            : formatNumber(filter.range[0]),
        header,
      )} - ${rangeFilterValue(
        filter.isDate
          ? formatDate(new Date(filter.range[1]))
          : filter.isDuration
            ? formatDuration({
                milliseconds: filter.range[1],
              })
            : formatNumber(filter.range[1]),
        header,
      )}`;
    case "text":
      return filter.value;
    default:
      return undefined;
  }
};

export const TableAppliedFilters: FC<TableAppliedFilterProps> = ({ filters, headers, removeFilter }) => {
  const filterValues = useMemo(
    () =>
      Object.entries(filters)
        .filter(([, filter]) => filter.isApplied)
        .map(([index, filter]) => ({
          index,
          header: headers[Number(index)].text,
          value: getFilterValue(filter, headers[Number(index)]),
        })),
    [filters, headers],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
        mr: filterValues.length ? 2 : 0,
      }}
    >
      {filterValues.map(({ index, header, value }) => (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box mr={1}>
            <Typography variant="subtitle1">{header}:</Typography>
          </Box>
          <Box
            key={index}
            bgcolor="greys.900"
            display="flex"
            alignItems="center"
            whiteSpace="nowrap"
            borderRadius="50px"
            border="1px solid"
            borderColor="greys.700"
            px={1}
            data-testid={`applied-filter-${index}`}
          >
            {value ? (
              <Tooltip title={value}>
                <Box
                  mr={1}
                  height={24}
                  minWidth={24}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  maxWidth="250px"
                >
                  <Typography variant="h5" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {value}
                  </Typography>
                </Box>
              </Tooltip>
            ) : (
              <Typography variant="h5">-</Typography>
            )}
            <IconButton sx={{ svg: { width: 16, height: 16 }, p: 0 }} onClick={() => removeFilter(Number(index))}>
              <CustomIcon icon="close" />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
