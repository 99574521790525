import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useAuth } from "@frontend/ui/auth";
import HoldersAmount from "./components/amount";
import { ETHERFI_APPROVED_ROLE } from "../../../../utils/consts";
import { NotPermittedError } from "../../../../components/not-permitted-error";
import TopHoldersTable from "./components/top-holders-table";
import { TvlByProtocol } from "./components/tvl-by-protocol-chart";
import { ChartsGrid } from "./components/charts-grid";

const HoldersTab = () => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Quarter);
  const auth = useAuth();

  if (!auth.hasRole(ETHERFI_APPROVED_ROLE)) {
    return <NotPermittedError />;
  }

  return (
    <Box>
      <PageHeader showBackButton={false} pageTitle="Holders" />
      <HoldersAmount timeSpan={timeSpan} onTimeSpanChange={setTimeSpan} />
      <TopHoldersTable />
      <TvlByProtocol />
      <ChartsGrid timeSpan={timeSpan} />
    </Box>
  );
};

export default HoldersTab;
