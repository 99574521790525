import { Button } from "../../button";
import { CustomIcon } from "../../custom-icon";
import { Tooltip } from "../../tooltip";

type Props = {
  text: string;
  tooltip: string;
  href: string;
};

export const ExternalLinkCell = ({ text, tooltip, href }: Props) => (
  <Tooltip title={tooltip}>
    <Button
      variant="text"
      size="small"
      href={href}
      target="blank"
      endIcon={<CustomIcon size="x-small" icon="external-link" />}
      onClick={(e) => e.stopPropagation()}
    >
      {text}
    </Button>
  </Tooltip>
);
