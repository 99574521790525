import { Grid, ValueOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useLiquidityUtilization14DaysMovingAverageQuery } from "../../../generated";

type Props = {
  timeSpan: TimeSpan;
};

export const UtilizationMovingAverageChart = ({ timeSpan }: Props) => {
  const { data, loading } = useLiquidityUtilization14DaysMovingAverageQuery({ variables: { timeSpan } });
  const { liquidityUtilization14DaysMovingAverage = [] } = data || {};
  const chainToSeries = liquidityUtilization14DaysMovingAverage.reduce(
    (acc, point) => {
      const { timestamp, chain, utilization14dMovingAvg } = point;
      if (!acc[chain]) {
        acc[chain] = [];
      }
      acc[chain].push([timestamp, utilization14dMovingAvg]);
      return acc;
    },
    {} as { [key: string]: [number, number][] },
  );

  const series = Object.keys(chainToSeries).map((key) => ({
    label: key,
    data: chainToSeries[key],
  }));

  return (
    <Grid item xs={12} lg={6}>
      <ValueOverTimeChart
        title="DEX 14-Day Moving Average Liquidity Utilization"
        series={series}
        isLoading={loading}
        currency=""
        sortTooltipValues
        tooltip="Utilization is calculated as the ratio of volume to liquidity "
      />
    </Grid>
  );
};
