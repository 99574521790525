import { FC } from "react";
import { Typography } from "../typography";
import { Tooltip } from "../tooltip";
import { formatAmount } from "../utils/formatters";
import { CustomIcon } from "../custom-icon";

export type TrendProps = {
  delta: number;
  deltaTrend: number;
  tooltip?: string;
  deltaColor?: number;
  currency?: string;
  isPercent?: boolean;
  maximumFractionDigits?: number;
};

export function getDeltaFields(current?: number, previous?: number, isAlignedWithTrend = true, isDeltaPercent = true) {
  if (current !== undefined && previous !== undefined) {
    const change = current - previous;
    const changePercent = (current - previous) / (current === 0 ? 1 : current);
    const delta = Math.abs(isDeltaPercent ? changePercent : change);
    const deltaTrend = Math.sign(current - previous);
    const deltaColor = isAlignedWithTrend ? deltaTrend : -deltaTrend;

    return { delta, deltaTrend, deltaColor, isDeltaPercent };
  }

  return { delta: undefined, deltaTrend: undefined, deltaColor: undefined };
}

export function getDeltaFieldsFromChangePercent(changePercent?: number, isAlignedWithTrend?: boolean) {
  if (changePercent !== undefined) {
    const delta = Math.abs(changePercent);
    const deltaTrend = Math.sign(changePercent);
    const deltaColor = isAlignedWithTrend ? deltaTrend : -deltaTrend;

    return { delta, deltaTrend, deltaColor, isDeltaPercent: true };
  }

  return { delta: undefined, deltaTrend: undefined, deltaColor: undefined };
}

export const Trend: FC<TrendProps> = ({
  delta,
  deltaTrend,
  deltaColor = 0,
  currency,
  isPercent,
  tooltip = "Change over the last 24h",
  maximumFractionDigits,
}) => {
  const trendIndicator =
    deltaTrend === 0 ? "" : deltaTrend === -1 ? <CustomIcon icon="sort-down" /> : <CustomIcon icon="sort-up" />;

  const trendColor = deltaTrend === 0 ? undefined : deltaColor < 0 ? "error.main" : "success.main";

  if (delta === 0) return null;

  return (
    <Tooltip title={tooltip} arrow placement="bottom-start">
      <Typography color={trendColor} display="flex" alignItems="center" whiteSpace="nowrap" variant="h5">
        {trendIndicator} {formatAmount(Math.abs(delta), { isPercent, currency, maximumFractionDigits })}
      </Typography>
    </Tooltip>
  );
};
