import { useState } from "react";
import { Box, Button, CustomIcon, Grid, List, ListItem, Typography } from "@frontend/ui";
import { ModalWrapper } from "@frontend/ui/modals";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "../../../../context/providers-select-context";
import GrangerCausalityChart from "./granger-causality-chart";
import { useFeedData } from "../../use-price-feed-details";
import { Endpoints } from "../../../../api";
import { TimeSpanPicker } from "../../../../../ccar-lending-page/components/time-span-picker";

const GrangerCausalitySection = () => {
  const [learMoreOpen, setLearnMoreOpen] = useState(false);
  const { selectedProviders } = useProvidersSelectContext();
  const { response, isLoading, timeSpan, setTimeSpan } = useFeedData(Endpoints.PriceFeedGrangerCausality);

  if (!(selectedProviders || []).filter((p) => p.type === "oracle")?.length) return null;

  return (
    <>
      <Box mt={3} display="flex" justifyContent="space-between">
        <Box mx={3}>
          <Box display="flex" gap={1} alignItems="center">
            <CustomIcon icon="granger-causality" />
            <Typography variant="h3">Granger Causality</Typography>
          </Box>
          <Typography variant="h6">
            Measures the Granger Causality between the price returns measured by the benchmark oracle and other oracles
            at various lags{" "}
            <Button
              onClick={() => setLearnMoreOpen(true)}
              variant="text"
              size="small"
              disableRipple
              disableFocusRipple
              sx={{
                display: "inline-block",
                height: "24px",
                p: 0,
                verticalAlign: "baseline",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
              }}
            >
              <Typography variant="h5" color="blue.main">
                Learn More
              </Typography>
            </Button>
          </Typography>
        </Box>
        <TimeSpanPicker<PriceFeedTimeSpan>
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
          timeSpanOptions={priceFeedTimeSpanOptions}
          hideLabel
        />
      </Box>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <GrangerCausalityChart grangerCausality={response} valueType="likelihood" isLoading={isLoading} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GrangerCausalityChart grangerCausality={response} valueType="pValue" isLoading={isLoading} />
        </Grid>
      </Grid>
      <ModalWrapper
        open={learMoreOpen}
        onClose={() => setLearnMoreOpen(false)}
        title="Understanding Granger Causality"
        maxWidth={1000}
      >
        <Typography variant="h6">
          The Granger Causality approach analyzes the predictive relationship between price returns reported by the
          benchmark oracle and those from other oracle providers within a 1-hour window. It calculates the likelihood
          ratio and its p-value to determine if the benchmark oracle's prices lead or lag behind those of other
          providers. The likelihood indicates the predictive strength of one price series over another, and a
          significant p-value suggests a reliable predictive relationship. We report the median p-value and the median
          likelihood ratio.
        </Typography>
        <Typography variant="h5" mt={4}>
          Method Parameters:
        </Typography>
        <List sx={{ pl: 4, listStyle: "outside" }}>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Resampling Frequency:
              </Typography>{" "}
              Data is resampled every second, using the last price within each second for analysis.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Window Size:
              </Typography>{" "}
              The analysis covers a 1-hour period
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="h6">
              <Typography variant="h5" sx={{ display: "inline-block" }}>
                Time Lags:
              </Typography>{" "}
              Examines time lags of 1, 2, 3, and 4 seconds.
            </Typography>
          </ListItem>
        </List>
      </ModalWrapper>
    </>
  );
};
export default GrangerCausalitySection;
