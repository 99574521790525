import { formatDateAndTime } from "@frontend/ui/utils/formatters";
import { TagChip } from "@frontend/ui/tag-chip";
import { Box, CryptoIcon, Grid, ValueCard } from "@frontend/ui";
import { useHolderDetailsQuery, useStakingTopHoldersQuery } from "../../../generated";

type Props = {
  address: string;
};
export const TopHolderCards = ({ address }: Props) => {
  const { data } = useHolderDetailsQuery({
    variables: {
      address,
    },
  });
  const { data: tableData } = useStakingTopHoldersQuery();
  const { firstTokenAcquisition } = data?.holderDetails || {};

  const row = tableData?.stakingTopHolders.find((holder: { address: string }) => holder.address === address);
  const amountHeld = row?.eEthExposure ?? 0;

  return (
    <Grid container>
      <Grid item xs={6} md={4} justifyContent="top">
        <ValueCard title="Amount Held" value={amountHeld} cryptoCurrency="ETH" disableContainerSpaceBetween />
      </Grid>
      <Grid item xs={6} md={4}>
        <ValueCard
          title="First Token Acquisition"
          value={
            <Box display="flex" flexDirection="column" gap={1}>
              {firstTokenAcquisition?.length
                ? firstTokenAcquisition?.map((token) => (
                  <Box key={token.symbol} fontSize={14}>
                    <TagChip
                      label={token.symbol}
                      icon={<CryptoIcon icon={token.symbol.toLowerCase()} size="small" />}
                    />{" "}
                    {formatDateAndTime(new Date(token.date))}
                  </Box>
                  ))
                : "-"}
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
};
