import { Box, PieChart } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { useParams } from "react-router-dom";
import { useDepositDetailsDistributionQuery } from "src/pages/risk-monitoring-staking/generated";
import DepositDetailsTable from "./deposit-details-table";

const DepositDrillDown = () => {
  const { address } = useParams();
  const { loading, data } = useDepositDetailsDistributionQuery({ variables: { depositor: address || "" } });

  return (
    <Box>
      <PageHeader showBackButton pageTitle={formatAddressCompact(address || "")} />
      <PieChart
        title="Deposit Origin Breakdown"
        isLoading={loading}
        emptyState={!data?.depositDetailsDistribution.length && !loading}
        series={[
          {
            data: data?.depositDetailsDistribution.map((w) => [w.category, w.txValue]) ?? [],
          },
        ]}
      />
      <DepositDetailsTable title="Previous Usage of Deposit Funds" />
    </Box>
  );
};

export default DepositDrillDown;
