import { useParams } from "react-router-dom";
import { ValueOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { ExchangeRatePoint, useWeEthExchangeRateOverTimeQuery } from "../../../../generated";
import { useProtocolPoolSelection } from "./protocol-pool-selection";

interface Props {
  timeSpan: TimeSpan;
  isLoadingProtocols: boolean;
}

interface Params extends Record<string, string | undefined> {
  chain?: string;
}
export const WeEthExchangeRateOverTimeChart = ({ timeSpan, isLoadingProtocols }: Props) => {
  const { selectedProtocol, selectedPool } = useProtocolPoolSelection();
  const { chain } = useParams<Params>();
  const { data, loading } = useWeEthExchangeRateOverTimeQuery({
    skip: !selectedProtocol || !chain || !selectedPool,
    variables: {
      timeSpan,
      selectedPool: selectedPool!,
      selectedProtocol: selectedProtocol!,
      chain: chain!,
    },
  });

  return (
    <ValueOverTimeChart
      title="weETH Exchange Rate Over Time"
      yAxisMin
      yAxisMax
      series={[
        {
          data:
            data?.weEthExchangeRateOverTime?.map(
              (point: ExchangeRatePoint) => [point.timestamp, point.exchangeRate] as [number, number],
            ) ?? [],
          label: "weETH Exchange Rate",
        },
      ]}
      isLoading={loading || isLoadingProtocols}
      connectNulls
      cryptoCurrency={data?.weEthExchangeRateOverTime?.[0]?.denomination}
    />
  );
};
