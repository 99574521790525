import { useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import { TimeSpan } from "@frontend/ui/echarts/types";
import {
  Chain,
  StablecoinSnapshot,
  useAssetStatsQuery,
  useMarketAssetsBreakdownQuery,
  useStablecoinHistoryQuery,
  useStablecoinPriceChangeLiquidationQuery,
} from "../../generated";
import { useClientConfig } from "../../clients-config";
import { StablecoinData } from "./types";

export const useStablecoinData = (timeSpan: TimeSpan): StablecoinData => {
  const { stableCoin } = useClientConfig();
  const [lastHistoryPoint, setLastHistoryPoint] = useState<StablecoinSnapshot>();

  const asset = stableCoin?.asset || "";
  const chain = stableCoin?.chain || Chain.Ethereum;
  const { data: assetStatsData, loading: isLoadingAssetStats } = useAssetStatsQuery({
    variables: {
      chain,
      tokenSymbol: asset,
      marketId: stableCoin?.marketId ?? null,
      market: null,
      marketType: null,
    },
  });

  const priceChange = useStablecoinPriceChangeLiquidationQuery({
    variables: {
      chain,
      stablecoin: asset,
    },
    skip: !stableCoin?.asset || !stableCoin.chain,
  });

  const historyResponse = useStablecoinHistoryQuery({
    variables: {
      timeSpan,
      symbol: asset,
      chain,
    },
  });

  const history = historyResponse?.data?.stablecoinHistory;
  useEffect(() => {
    const historyPoint = history?.[(history?.length || 0) - 1];
    if (historyPoint) {
      setLastHistoryPoint(historyPoint);
    }
  }, [history]);

  const marketAssetsBreakdown = useMarketAssetsBreakdownQuery();

  const isLoading =
    priceChange.loading || historyResponse.loading || marketAssetsBreakdown.loading || isLoadingAssetStats;

  const liquidationData = priceChange.data?.stablecoinPriceChangeLiquidation || [];
  const liquidationDataByKey = Object.fromEntries(
    new Map(
      liquidationData.map((d) => [
        d.asset,
        {
          currentMarketAssetPrice: d.currentAssetPrice,
          liquidationUsdValueByPrice: Object.fromEntries(
            new Map(d.liquidationUsdValueByPrice.map((liq) => [`${liq.price}`, liq.liquidationAmount])),
          ),
        },
      ]),
    ),
  );

  return {
    isLoading,
    tokenSymbol: stableCoin?.asset || "",
    priceChangeLiquidation: liquidationDataByKey,
    history,
    marketAssets: keyBy(marketAssetsBreakdown?.data?.assetsBreakdown, "asset"),
    price: assetStatsData?.assetStats.current.coinGeckoPrice ?? undefined,
    assetStats: assetStatsData?.assetStats,
    marketCap: lastHistoryPoint?.marketCap || undefined,
    volume: lastHistoryPoint?.volume24h || undefined,
  };
};
