import { TimeSpan } from "@frontend/ui/echarts/types";
import { CompositionOverTimeChart, Grid } from "@frontend/ui";
import { useExitLiquidityByTokenQuery } from "../../../generated";

type Props = {
  timeSpan: TimeSpan;
};
export const ExitLiquidityByTokenChart = ({ timeSpan }: Props) => {
  const { data, loading } = useExitLiquidityByTokenQuery({ variables: { timeSpan } });
  const { exitLiquidityByToken = [] } = data || {};
  const seriesByTokenOverTime: { [key: string]: [number, number][] } = exitLiquidityByToken?.reduce(
    (acc, point) => {
      const { timestamp, exitLiquidityInEth, tokenSymbol } = point;
      if (!exitLiquidityInEth) return acc;
      if (!acc[tokenSymbol]) {
        acc[tokenSymbol] = [];
      }
      acc[tokenSymbol].push([timestamp, exitLiquidityInEth]);
      return acc;
    },
    {} as { [key: string]: [number, number][] },
  );

  return (
    <Grid item xs={12} lg={6}>
      <CompositionOverTimeChart
        title="Exit Liquidity TVL by Token"
        series={Object.keys(seriesByTokenOverTime).map((key) => ({
          label: key,
          data: seriesByTokenOverTime[key],
        }))}
        isLoading={loading}
        cryptoCurrency="ETH"
        tooltipValueFormatter={(value) => `${value} ETH`}
      />
    </Grid>
  );
};
