import { CompositionOverTimeChart, Grid, ValueOverTimeChart } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useWeEthAsCollateralQuery, WeEthAsCollateralChartPoint } from "../../../generated";

type Props = {
  timeSpan: TimeSpan;
};

export const WeEthAsCollateral = ({ timeSpan }: Props) => {
  const { loading, data } = useWeEthAsCollateralQuery({ variables: { timeSpan } });

  const seriesByChainOverTime: { [key: string]: [number, number][] } = {};
  const seriesByProtocolOverTime: { [key: string]: [number, number][] } = {};
  const percentageOverTime: [number, number][] = [];
  (data?.weEthAsCollateral?.filter((point): point is WeEthAsCollateralChartPoint => point !== null) ?? []).forEach(
    (point: WeEthAsCollateralChartPoint) => {
      const { timestamp, weEthCollateralPercentage, weEthCollateralPerProtocol, weEthCollateralPerChain } = point;
      percentageOverTime.push([timestamp, weEthCollateralPercentage / 100]);
      weEthCollateralPerProtocol.forEach(({ category, weEthAsCollateralEth }) => {
        if (!seriesByProtocolOverTime[category]) {
          seriesByProtocolOverTime[category] = [];
        }
        seriesByProtocolOverTime[category].push([timestamp, weEthAsCollateralEth]);
      });
      weEthCollateralPerChain.forEach(({ category, weEthAsCollateralEth }) => {
        if (!seriesByChainOverTime[category]) {
          seriesByChainOverTime[category] = [];
        }
        seriesByChainOverTime[category].push([timestamp, weEthAsCollateralEth]);
      });
    },
  );

  const seriesByChainOverTimeArray = Object.keys(seriesByChainOverTime).map((key) => ({
    label: key,
    data: seriesByChainOverTime[key],
  }));

  const seriesByProtocolOverTimeArray = Object.keys(seriesByProtocolOverTime).map((key) => ({
    label: key,
    data: seriesByProtocolOverTime[key],
  }));

  return (
    <>
      <Grid item xs={12} lg={6}>
        <CompositionOverTimeChart
          title="weETH as Collateral on Lending Protocols by Chain"
          isLoading={loading}
          series={seriesByChainOverTimeArray}
          cryptoCurrency="ETH"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CompositionOverTimeChart
          title="weETH as Collateral on Lending Protocols by Protocol"
          isLoading={loading}
          series={seriesByProtocolOverTimeArray}
          cryptoCurrency="ETH"
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ValueOverTimeChart
          title="weETH Collateral Percentage of Total Supply"
          description="Proportion of weETH supply allocated as collateral in Lending Protocols"
          isLoading={loading}
          series={[
            {
              label: "Percentage",
              data: percentageOverTime,
              type: "line",
            },
          ]}
          isPercent
          showLegend={false}
        />
      </Grid>
    </>
  );
};
