import { renderToString } from "react-dom/server";
import { TooltipComponentFormatterCallbackParams } from "echarts";
import { Box, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";

const DataRow = ({ title, value }: { title: string; value: string | number }) => (
  <Box display="flex" justifyContent="space-between" gap={2}>
    <Typography variant="caption">{title}</Typography>
    <Typography variant="caption">{value}</Typography>
  </Box>
);

export const depthChartTooltipFormatter =
  (baseAssetSymbol: string) => (params: TooltipComponentFormatterCallbackParams) => {
    const paramsArr = Array.isArray(params) ? params : [params];
    const data = paramsArr[0]?.data as [number, number];

    if (!data) return "";

    const [basePrice, totalSize] = data;
    const totalCost = basePrice * totalSize;

    return renderToString(
      <Box display="flex" flexDirection="column" p={1}>
        <DataRow title="Price" value={formatAmount(basePrice, { currency: "USD" })} />
        <DataRow title="Total Size" value={`${formatAmount(totalSize)} ${baseAssetSymbol}`} />
        <DataRow title="Total Cost" value={formatAmount(totalCost, { currency: "USD" })} />
      </Box>,
    );
  };
