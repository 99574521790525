import { ChaosTable, currencyCell, dateCell, linkCell, numberCell, textCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { getTxDetailsUrl } from "@frontend/ui/utils/chain-url-mapper";
import { useHolderEventsQuery } from "../../../generated";
import { Chain } from "../../../../ccar-lending-page/generated";

export const TopHolderEventsTable = ({ address }: { address: string }) => {
  const { data, loading } = useHolderEventsQuery({
    variables: {
      address,
    },
  });

  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Date",
    },
    {
      renderType: "TEXT",
      text: "Block Number",
    },
    {
      renderType: "TEXT",
      text: "Amount",
    },
    {
      renderType: "TEXT",
      text: "Counterparty",
    },
    {
      renderType: "TEXT",
      text: "Tx Hash",
    },
  ];

  const rows: RenderData[][] =
    data?.holderEvents.map((e) => [
      dateCell(new Date(e.date), false),
      textCell(String(e.blockNumber)),
      currencyCell(e.amount, { cryptoCurrency: "ETH" }),
      textCell(e.counterpartyLabel ?? e.counterparty),
      linkCell(formatAddressCompact(e.txHash), getTxDetailsUrl(Chain.Ethereum, e.txHash), e.txHash),
    ]) ?? [];
  return (
    <ChaosTable
      data={rows}
      isLoading={loading}
      headers={headers}
      title="Recent Events"
      initialSortBy={0}
      isInitialSortDesc
      pageSize={10}
      isFullHeight
      isFilterHidden
    />
  );
};
