import { Box, CompositionOverTimeChart, Grid } from "@frontend/ui";
import { useState } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useHolderAggregatedHoldingsQuery } from "../../../generated";
import { TimeSpanPicker } from "../../../../ccar-lending-page/components/time-span-picker";

type Props = {
  address: string;
};

const TopHolderHoldingsCharts = ({ address }: Props) => {
  const [timeSpan, setTimeSpan] = useState(TimeSpan.Quarter);
  const { data, loading } = useHolderAggregatedHoldingsQuery({
    variables: {
      address,
      timeSpan,
    },
  });

  const { holderAggregatedHoldings } = data || {};

  const seriesByChainOverTime: { [key: string]: [number, number][] } = {};
  const seriesByProtocolOverTime: { [key: string]: [number, number][] } = {};
  (holderAggregatedHoldings || []).forEach((point) => {
    const { timestamp, holdingsPerChain, holdingsPerProtocol } = point;
    holdingsPerProtocol.forEach(({ category, balanceInEth }) => {
      if (!seriesByProtocolOverTime[category]) {
        seriesByProtocolOverTime[category] = [];
      }
      seriesByProtocolOverTime[category].push([timestamp, balanceInEth]);
    });
    holdingsPerChain.forEach(({ category, balanceInEth }) => {
      if (!seriesByChainOverTime[category]) {
        seriesByChainOverTime[category] = [];
      }
      seriesByChainOverTime[category].push([timestamp, balanceInEth]);
    });
  });

  const seriesByChainOverTimeArray = Object.keys(seriesByChainOverTime).map((key) => ({
    label: key,
    data: seriesByChainOverTime[key],
  }));

  const seriesByProtocolOverTimeArray = Object.keys(seriesByProtocolOverTime).map((key) => ({
    label: key,
    data: seriesByProtocolOverTime[key],
  }));

  return (
    <Box>
      <Box
        sx={{
          float: "right",
        }}
      >
        <TimeSpanPicker
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
          timeSpanOptions={[TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year]}
        />
      </Box>
      <Grid container mb={4}>
        <Grid item xs={12} lg={6}>
          <CompositionOverTimeChart
            title="EtherFi Asset Holdings By Chain Over Time"
            series={seriesByChainOverTimeArray}
            isLoading={loading}
            cryptoCurrency="ETH"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CompositionOverTimeChart
            title="EtherFi Asset Holdings By Protocol Over Time"
            series={seriesByProtocolOverTimeArray}
            isLoading={loading}
            cryptoCurrency="ETH"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopHolderHoldingsCharts;
