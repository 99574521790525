import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "@frontend/ui/auth";
import { OraclePortalLayout } from "./layout";
import { RoutePath } from "../../../config/routes";
import PriceFeedsProvider from "../context/price-feeds-context";
import { PriceFeeds } from "../tabs";
import { PriceFeedDetails } from "../pages";
import { TemporaryUnavailable } from "./temporary-unavailable";
import { CHAOS_LABS_MEMBER_ROLE } from "../../../utils/consts";

export const replaceHomePath = (path: string) => path.replace(RoutePath.OraclePortal.Home, "");

const OraclePortalRoutes = () => {
  const auth = useAuth();
  return (
    <PriceFeedsProvider>
      <Routes>
        <Route index element={<Navigate to={RoutePath.OraclePortal.PriceFeeds} replace />} />
        {!auth.hasRole(CHAOS_LABS_MEMBER_ROLE) ? (
          <Route
            path={replaceHomePath(RoutePath.OraclePortal.PriceFeeds)}
            element={
              <OraclePortalLayout hideTabs>
                <TemporaryUnavailable />
              </OraclePortalLayout>
            }
          />
        ) : (
          <>
            <Route
              path={replaceHomePath(RoutePath.OraclePortal.PriceFeeds)}
              element={
                <OraclePortalLayout>
                  <PriceFeeds />
                </OraclePortalLayout>
              }
            />
            <Route
              path={replaceHomePath(RoutePath.OraclePortal.PriceFeedDetails)}
              element={
                <OraclePortalLayout>
                  <PriceFeedDetails />
                </OraclePortalLayout>
              }
            />
            <Route path="*" element={<Navigate to={RoutePath.OraclePortal.Home} replace />} />
          </>
        )}
      </Routes>
    </PriceFeedsProvider>
  );
};
export default OraclePortalRoutes;
