import { useMemo } from "react";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, EmptyStateProps, markedCell, useTheme } from "@frontend/ui";
import { formatAddressCompact, formatAmount } from "@frontend/ui/utils/formatters";
import { CollateralizedWallet } from "src/pages/ccar-lending-page/generated";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import {
  getHealthTooltip,
  getRiskLabel,
  getRiskLabelColor,
  getStatusChangeTooltip,
} from "src/pages/ccar-lending-page/utils/health-utils";
import { getChainDisplayName } from "src/pages/ccar-lending-page/tabs/alerts/utils";
import { RiskStatus } from "./types";

const pageSize = 15;

type Props = {
  wallets: CollateralizedWallet[];
  description?: string;
  rowHref?: (rowId: number) => string;
  isLoading?: boolean;
  emptyState?: EmptyStateProps;
};

const WalletsAtRiskTable = ({ wallets, description, rowHref, isLoading, emptyState }: Props) => {
  const theme = useTheme();
  const { eligibleThreshold, riskThreshold, isHealthPercent, riskExplorer } = useClientConfig();
  const walletsTableHeaders: Header[] = useMemo(
    () => [
      {
        renderType: "TEXT",
        text: "Chain",
        width: "12%",
      },
      {
        renderType: "TEXT",
        text: "Wallet",
        field: "id",
        width: "12%",
      },
      {
        renderType: "TEXT",
        text: "Collateral",
        field: "totalCollateralUSD",
        width: "12%",
      },
      {
        renderType: "TEXT",
        text: "Borrow",
        field: "totalBorrowsUSD",
        width: "12%",
      },
      {
        renderType: "TEXT",
        text: "Potential Bad Debt",
        field: "potentialBadDebtUsd",
        width: "12%",
        tooltip: "Potential bad debt, calculated as (collateral - borrow) with the simulated price.",
      },
      {
        renderType: "TEXT",
        text: "Health",
        field: "healthFactor",
        width: "12%",
      },
      {
        renderType: "TEXT",
        text: "Change",
        width: "5%",
        textHidden: true,
      },
      {
        renderType: "TEXT",
        text: "Assets",
        nonSortable: true,
        width: "12%",
      },
    ],
    [],
  );

  const walletsTableRows: RenderData[][] = useMemo(
    () =>
      (wallets || []).map(
        ({
          address,
          health,
          totalCollateralUsd,
          totalBorrowsUsd,
          potentialBadDebtUsd,
          becameAtRisk,
          becameEligibleForLiquidation,
          positions,
          chainHealth,
          addressLabel,
        }) => {
          const chainText = chainHealth?.[0].chain ? getChainDisplayName(chainHealth?.[0].chain) : "";
          const riskStatus =
            health < Number(riskThreshold) && health < Number(eligibleThreshold)
              ? RiskStatus.ELIGIBLE_FOR_LIQUIDATION
              : RiskStatus.AT_RISK_FOR_LIQUIDATION;
          const riskStatusChange = becameAtRisk || becameEligibleForLiquidation;
          return [
            {
              renderType: "TEXT",
              text: chainText,
              tooltipText: chainText,
              token1: chainHealth?.[0].chain,
            },
            {
              renderType: "TEXT",
              text: addressLabel || formatAddressCompact(address),
              tooltipText: address,
              searchTerm: address,
              copyText: address,
              exportText: address,
            },
            {
              renderType: "TEXT",
              text: formatAmount(totalCollateralUsd, { currency: "USD" }),
              tooltipText: formatAmount(totalCollateralUsd, { currency: "USD", notation: "standard" }),
              value: Number(totalCollateralUsd) || 0,
            },
            {
              renderType: "TEXT",
              text: totalBorrowsUsd ? formatAmount(totalBorrowsUsd, { currency: "USD" }) : "-",
              tooltipText: totalBorrowsUsd
                ? formatAmount(totalBorrowsUsd, { currency: "USD", notation: "standard" })
                : undefined,
              value: Number(totalBorrowsUsd) || 0,
            },
            {
              renderType: "TEXT",
              text: formatAmount(potentialBadDebtUsd, { currency: "USD" }),
              tooltipText: potentialBadDebtUsd
                ? formatAmount(potentialBadDebtUsd, { currency: "USD", notation: "standard" })
                : undefined,
              value: potentialBadDebtUsd,
            },
            riskExplorer?.showLiquidationRisk
              ? {
                  renderType: "CHIP",
                  text: getRiskLabel(riskStatus),
                  value: getRiskLabel(riskStatus),
                  textColor: getRiskLabelColor(theme, riskStatus),
                  tooltipText: getHealthTooltip(health || 0, isHealthPercent),
                }
              : {
                  renderType: "CHIP",
                  value: health || 0,
                  text: formatAmount(health, {
                    notation: "compact",
                  }),
                  textColor:
                    (health || 0) < 1
                      ? theme.palette.error.main
                      : health < 1.2
                        ? theme.palette.warning.main
                        : theme.palette.success.main,
                  width: "70px",
                  tooltipText: getHealthTooltip(health || 0, isHealthPercent),
                },
            markedCell(
              riskStatusChange,
              "Change in Risk Status",
              getStatusChangeTooltip(riskStatus || 0, riskStatusChange),
            ),
            {
              renderType: "ICONS",
              text: "",
              icons: positions,
              value: positions.join(";"),
            },
          ] as RenderData[];
        },
      ),
    [wallets, riskThreshold, eligibleThreshold, riskExplorer?.showLiquidationRisk, isHealthPercent, theme],
  );

  return (
    <ChaosTable
      title="Wallets at Risk of Liquidation"
      description={description}
      headers={walletsTableHeaders}
      data={walletsTableRows}
      pageSize={pageSize}
      isInitialSortEnable
      initialSortBy={1}
      isInitialSortDesc
      rowHref={rowHref}
      showSearch
      isLoading={isLoading}
      showRowChevron={!!rowHref}
      emptyState={emptyState}
      isTableControlsHidden={walletsTableRows.length === 0}
    />
  );
};

export default WalletsAtRiskTable;
