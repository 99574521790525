import { useState } from "react";
import { Box, Button, ChaosTable, IconButton, Typography } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { formatAmount } from "@frontend/ui/utils/formatters";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { WebhookFormDrawer } from "./webhook-form-drawer";
import { AlertWebhook } from "./utils";
import { CcarClient, mapProtocolToData } from "../alerts/utils";
import { usePageTitle } from "../../../../hooks";
import { dataFetchersURL } from "../../../../utils/data-fetchers-url";
import { useAlertWebhooks } from "../../hooks/useWebhooks";
import { useAlertSettings } from "../settings/use-settings-table-data";
import { AlertSetting } from "../settings/utils";

const WEBHOOKS_PER_PAGE = 10;

const alertWebhooksHeaders: Header[] = [
  {
    renderType: "TEXT",
    text: "Name",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Alerts",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Clients",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Actions",
    nonSortable: true,
    width: "80px",
  },
];

const mapAlertWebhook = (
  alertWebhook: AlertWebhook,
  alertSettings: AlertSetting[],
  onDelete: (id: string) => Promise<void>,
  onEdit: (webhook: AlertWebhook) => Promise<void>,
): RenderData[] => {
  const alertsCount = alertWebhook.allAlertsEnabled ? alertSettings?.length ?? 0 : alertWebhook.alertIds?.length ?? 0;
  const clientsCount = alertWebhook.allClientsEnabled ? Object.values(CcarClient) : alertWebhook.ccarClients;
  return [
    {
      renderType: "TEXT",
      text: alertWebhook?.name ?? "",
    },
    {
      renderType: "CHIP",
      text: formatAmount(alertsCount, { notation: "standard" }),
      value: alertsCount,
      tooltipText: alertWebhook.allAlertsEnabled
        ? "All"
        : alertSettings
            .filter((a) => alertWebhook.alertIds?.includes(a.alertId))
            ?.map((a) => a.name)
            ?.join(", "),
    },
    alertWebhook.allClientsEnabled
      ? {
          renderType: "TEXT",
          text: "All",
        }
      : {
          renderType: "ICONS",
          text: clientsCount?.map((client) => client.toString()).join(", ") ?? "",
          icons:
            clientsCount
              ?.map((c) => {
                const { icon } = mapProtocolToData[c as CcarClient] ?? {};
                return icon;
              })
              .filter((c) => !!c) || [],
          textSuffix: "",
        },
    {
      renderType: "CUSTOM",
      text: "",
      component: (
        <Box display="flex">
          <IconButton
            aria-label="edit"
            onClick={() => {
              void onEdit(alertWebhook);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              void onDelete(alertWebhook.name);
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      ),
    },
  ];
};

export const WebhooksTab = () => {
  usePageTitle("Webhooks");

  const { data, loading, refresh } = useAlertWebhooks();
  const { alertSettings } = useAlertSettings();
  const [openForm, setOpenForm] = useState(false);
  const [editedWebhook, setEditedWebhook] = useState<AlertWebhook | null>(null);

  const handleOnFormClose = () => {
    setEditedWebhook(null);
    setOpenForm(false);
    void refresh?.();
  };

  const handleDelete = async (name: string) => {
    const res = await axios.delete<AlertWebhook>(`${dataFetchersURL()}data/webhooks`, {
      data: { name },
      withCredentials: true,
    });

    if (res.status === 200) {
      void refresh?.();
    }
  };

  const handleEdit = async (webhook: AlertWebhook) => {
    setEditedWebhook(webhook);
    setOpenForm(true);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" my={5}>
        <Typography variant="h1">Webhooks</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpenForm(true);
          }}
        >
          Add webhook
        </Button>
      </Box>
      <ChaosTable
        pageSize={WEBHOOKS_PER_PAGE}
        isFullHeight
        isLoading={loading}
        isSettingsHidden
        title=" "
        headers={alertWebhooksHeaders}
        data={data?.map((d) => mapAlertWebhook(d, alertSettings, handleDelete, handleEdit)) ?? []}
        isFilterHidden
        emptyState={{
          icon: "warning",
          title: "No Webhooks Found",
        }}
      />
      <WebhookFormDrawer open={openForm} onClose={handleOnFormClose} webhook={editedWebhook} />
    </Box>
  );
};
