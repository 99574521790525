import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui/chaos-table";
import { useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { MarketData, Token } from "src/pages/ccar-lending-page/generated";
import { Client } from "src/pages/ccar-lending-page/types";
import { getSupplyTableHeaders, getCollateralAndBorrowTableHeaders } from "./tables-headers";
import { getSupplyTableData, getCollateralAndBorrowTableData } from "./positions-table-data";

type Props = {
  markets: MarketData[];
  data: Token[];
  metadata?: string;
};

const WalletPositionsTables = ({ data, markets, metadata }: Props) => {
  const { clientName } = useParams<{ clientName: Client }>();
  const { marketType, isMultiMarket, showChainMarketsBreakdown } = useClientConfig();

  const handleOnClickRow = (positions: Token[]) => (rowIdx: number) => {
    const position = positions?.[rowIdx];

    if (isMultiMarket) {
      return RoutePath.Risk.MarketAssetOverview.replace(RouteParams.ClientName, clientName!)
        .replace(RouteParams.MarketId, position.marketId)
        .replace(RouteParams.Asset, position.assetSymbol);
    }

    return RoutePath.Risk.ChainAssetOverview.replace(RouteParams.ClientName, clientName!)
      .replace(RouteParams.Chain, position.marketId)
      .replace(RouteParams.Asset, position.assetSymbol);
  };

  const supplyData = data.filter((token) => token.supplyAmount);
  const collateralAndBorrowData = data.filter((token) => token.collateralAmount || token.borrowAmount);

  const supplyHeaders: Header[] = getSupplyTableHeaders(isMultiMarket, showChainMarketsBreakdown);
  const supplyTableData: RenderData[][] = getSupplyTableData(
    supplyData,
    marketType,
    isMultiMarket,
    markets,
    showChainMarketsBreakdown,
  );

  const collateralAndBorrowHeaders: Header[] = getCollateralAndBorrowTableHeaders(
    isMultiMarket,
    showChainMarketsBreakdown,
  );
  const collateralAndBorrowTableData: RenderData[][] = getCollateralAndBorrowTableData(
    collateralAndBorrowData,
    marketType,
    isMultiMarket,
    markets,
    showChainMarketsBreakdown,
  );

  const commonTableProps = {
    pageSize: 10,
    isSettingsHidden: false,
    initialSortBy: 0,
    isInitialSortDesc: true,
    isFullHeight: true,
    metadata,
  };

  return (
    <>
      {!!supplyTableData.length && (
        <ChaosTable
          title="Supply"
          description="Showing non-collateral assets supplied across all markets"
          headers={supplyHeaders}
          data={supplyTableData}
          rowHref={handleOnClickRow(supplyData)}
          {...commonTableProps}
        />
      )}
      <ChaosTable
        title="Collateral & Borrow"
        description="Showing borrow and collateral assets balance across all markets"
        headers={collateralAndBorrowHeaders}
        data={collateralAndBorrowTableData}
        rowHref={handleOnClickRow(collateralAndBorrowData)}
        {...commonTableProps}
      />
    </>
  );
};

export default WalletPositionsTables;
