import { Chain } from "../../generated";
import { CCARClientConfig } from "../types";

export const zerolendConfig: CCARClientConfig = {
  chains: [Chain.Ethereum, Chain.Linea],
  isMultichain: true,
  isMultiMarket: true,
  marketType: "ChainMarket",
  endpointsPrefix: "zerolend",
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  overview: {
    cards: [
      "totalSupplyUsd",
      "totalBorrowUsd",
      "totalValueLockedUsd",
      "totalValueAvailableForLiquidationUsd",
      "totalValueAtRiskUsd",
      "numberOfWalletsAtRisk",
      "numberOfWalletsAvailableForLiquidation",
      "totalPotentialBadDebtUsd",
    ],
    charts: ["historyStatsTvl", "chainHistoryStats", "aggSupplyBorrowEvents"],
    chainHistoryStats: {
      disabled: true,
    },
  },
  wallets: {
    hiddenRows: {
      badDebt: true,
    },
  },
  walletDetails: {
    tables: ["totalSupplyAndBorrow", "recentEvents"],
  },
  marketDetails: {
    tables: ["wallets", "assetEvents"],
    charts: [
      "historicalPrices",
      "collateralAtRisk",
      "supplyWalletsHealth",
      "borrowWalletsHealth",
      "marketsDistribution",
      "topWallets",
    ],
    liquidationThresholdLabel: "Loan to Value",
  },
  liquidations: {
    showLiquidationPie: true,
    showLiquidationEvents: true,
  },
  riskExplorer: {
    liquidationThresholdLabel: "Collateral Factor",
    showAdvancedSettings: true,
  },
  alerts: {
    enabled: true,
    notificationsChannels: [
      {
        channel: "telegram",
        link: "https://t.me/+kxvfYjfvvf9kYTA5",
      },
    ],
  },
};
