import { Box, CryptoIcon, Typography } from "@frontend/ui";
import { useProvidersSelectContext } from "../../../context/providers-select-context";

const BenchmarkLabel = ({ color }: { color?: string }) => {
  const { selectedBaselineProvider } = useProvidersSelectContext();
  const { name, display_name: displayName } = selectedBaselineProvider || {};

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="caption" color={color}>
        Benchmark Oracle:
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        {name && <CryptoIcon size="small" icon={name} sx={{ pl: 1 }} />}
        <Typography variant="caption" color={color}>
          {displayName}
        </Typography>
      </Box>
    </Box>
  );
};

export default BenchmarkLabel;
