import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { OnChainLiquidityCards } from "./components/cards";
import { ChartsGrid } from "./components";
import { ChainOverviewTable } from "./components/chain-over-view-table";
import { MajorPoolsTable } from "./components/major-pools-table";

export const OnChainLiquidity = () => (
  <Box>
    <PageHeader showBackButton={false} pageTitle="On-Chain Liquidity" />
    <Box display="flex" flexDirection="column" gap={3}>
      <OnChainLiquidityCards />
      <ChartsGrid />
      <ChainOverviewTable />
      <MajorPoolsTable />
    </Box>
  </Box>
);
