import { Box } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { OverviewCards, OverviewCharts } from "./components";

const Overview = () => (
  <Box>
    <PageHeader showBackButton={false} pageTitle="Overview" />
    <Box display="flex" flexDirection="column" gap={3}>
      <OverviewCards />
      <OverviewCharts />
    </Box>
  </Box>
);

export default Overview;
