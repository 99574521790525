import { Box } from "@frontend/ui";
import { ChainsDropdownHeader, ChainOverviewCards, ChainOverviewCharts } from "./components";
import { LiquiditySection } from "./components/liquidity/liquidity-section";

export const ChainOverviewDetails = () => (
  <Box>
    <ChainsDropdownHeader />
    <ChainOverviewCards />
    <ChainOverviewCharts />
    <LiquiditySection />
  </Box>
);
