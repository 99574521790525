import { ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { Endpoints } from "src/pages/oracle-portal/api";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import { PriceFeedTimeSpan, priceFeedTimeSpanOptions } from "src/pages/oracle-portal/context/providers-select-context";
import { useFeedData } from "../../use-price-feed-details";

const ExchangesVwapChart = () => {
  const { response: exchangesVwap, isLoading, timeSpan, setTimeSpan } = useFeedData(Endpoints.PriceFeedVwap);

  const vwapSeries: NumberChartSeries = {
    label: "Volume Weighted Average Price",
    data: (exchangesVwap || []).map((p) => [p.timestamp * 1000, p.aggr_vwap]),
    type: "line",
  };

  const medianSeries: NumberChartSeries = {
    label: "Median Price",
    data: (exchangesVwap || []).map((p) => [p.timestamp * 1000, p.median_price]),
    type: "line",
  };

  return (
    <ValueOverTimeChart
      key="vwap-chart"
      title="Aggregated Exchanges Price"
      description="Consolidated prices across all listed exchanges in the providers section"
      series={[vwapSeries, medianSeries]}
      currency="USD"
      isLoading={isLoading}
      yAxisMax
      yAxisMin
      zoomable
      timeSpan={timeSpan}
      headerSuffix={
        <TimeSpanPicker<PriceFeedTimeSpan>
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
          timeSpanOptions={priceFeedTimeSpanOptions}
          hideLabel
        />
      }
    />
  );
};

export default ExchangesVwapChart;
