import { formatAmount } from "@frontend/ui/utils/formatters";
import { AssetPriceChangeLiquidation } from "../types";

export const formatPriceChangeLiquidationResponse = (data?: AssetPriceChangeLiquidation) => {
  if (!data) {
    return [];
  }

  const assets = Object.keys(data).sort();

  return assets.map((asset) => {
    const values = data[asset];
    const currentPrice = values.currentMarketAssetPrice;
    const priceChanges = Object.keys(values.liquidationUsdValueByPrice);

    return {
      id: asset,
      title: asset,
      data: priceChanges
        .map((item) => {
          const price = values.liquidationUsdValueByPrice[item as `${number}`];
          const x = parseFloat(item) / currentPrice - 1;

          return {
            x: Math.abs(x) < 0.0001 ? 0 : x,
            y: price,
          };
        })
        .sort((a, b) => (a.x > b.x ? 1 : -1))
        .map((d) => ({
          ...d,
          x: formatAmount(d.x, {
            isPercent: true,
            showSign: true,
            maximumFractionDigits: 0,
            roundingMode: "halfExpand",
          }),
        })),
    };
  });
};
