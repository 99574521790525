import { DatumValue } from "@nivo/core";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import duration from "dayjs/plugin/duration";
import { Notation, RoundingMode } from "./types";

dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

type FormatAmountProps = {
  isPercent?: boolean;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  notation?: Notation;
  roundingMode?: RoundingMode;
  cryptoCurrency?: string;
  showSign?: boolean;
};

const DEFAULT_NOTATION: Notation = "compact";

const DEFAULT_ROUNDING_MODE: RoundingMode = "floor";

const getMaxFractionDigits = (amount: number, props?: FormatAmountProps) => {
  const { notation, isPercent } = props || {};
  const absAmount = Math.abs(amount);

  if (absAmount > 0 && absAmount < 1) {
    return 4;
  }

  if (notation === "standard" && absAmount > 1000) {
    return 0;
  }

  if (notation === "standard" && absAmount < 10 && isPercent) {
    return 3;
  }

  return 2;
};

export const formatAmount = (amount: number, props?: FormatAmountProps) => {
  const {
    isPercent,
    currency,
    showSign,
    notation,
    minimumFractionDigits,
    maximumFractionDigits,
    roundingMode,
    cryptoCurrency,
  } = props || {};

  const style = isPercent ? "percent" : currency && !cryptoCurrency ? "currency" : "decimal";
  const amountToDisplay = isPercent ? amount * 100 : amount;

  if (Math.abs(amountToDisplay) > 0 && Math.abs(amountToDisplay) < 0.0001) {
    return `< ${currency ? "$" : ""}0.0001${isPercent ? "%" : ""}${cryptoCurrency ? ` ${cryptoCurrency}` : ""}`;
  }

  const options: Intl.NumberFormatOptions = {
    style,
    notation: notation ?? DEFAULT_NOTATION,
    currency,
    minimumFractionDigits: minimumFractionDigits ?? 0,
    maximumFractionDigits: maximumFractionDigits ?? getMaxFractionDigits(amountToDisplay, props),
    roundingMode: roundingMode ?? DEFAULT_ROUNDING_MODE,
  };

  const value = new Intl.NumberFormat("en-US", options).format(amount);

  const sign = amount > 0 ? "+" : "";

  return `${showSign ? sign : ""}${value}${cryptoCurrency ? ` ${cryptoCurrency}` : ""}`;
};

export const formatLongFormDate = (date: string): string => dayjs(date).format("MMMM DD, YYYY");

// https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
export const formatCsvDate = (date?: Date): string => dayjs(date).format("YYYY-MM-DD HH:mm:ss");

export const formatDate = (date?: Date, isFullDate?: boolean): string => {
  if (!date) return "";
  const dayjsDate = dayjs(date);
  const today = dayjsDate.isToday() && "Today";
  const yesterday = dayjsDate.isYesterday() && "Yesterday";
  const actualDate = date.toLocaleString(userLocale, { year: "numeric", month: "2-digit", day: "2-digit" });
  if (isFullDate) return actualDate;
  return `${today || yesterday || actualDate}`;
};

export const formatDay = (date?: Date, isFullDate?: boolean): string => {
  if (!date) return "";
  const dayjsDate = dayjs(date);
  const today = dayjsDate.isToday() && "Today";
  const yesterday = dayjsDate.isYesterday() && "Yesterday";
  const actualDate = date.toLocaleString(userLocale, { month: "2-digit", day: "2-digit" });
  if (isFullDate) return actualDate;
  return `${today || yesterday || actualDate}`;
};

export const formatTimeInDate = (date?: Date | string, withSeconds = true): string => {
  if (!date) return "";
  return date.toLocaleString(userLocale, {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
    second: withSeconds ? "2-digit" : undefined,
  });
};

export const formatDateAndTime = (date?: Date, withSeconds = true): string => {
  if (!date) return "";
  return `${formatDate(date)} at ${formatTimeInDate(date, withSeconds)}`;
};

export const diffDates = (date0?: Date, date1?: Date) => {
  const day1 = dayjs(date0);
  const day2 = dayjs(date1);

  let hours = day2.diff(day1, "hours");
  const days = Math.floor(hours / 24);
  hours -= days * 24;

  return `${days}d ${hours}H`;
};

export type FormatDurationProps = {
  milliseconds: number;
  notation?: "standard" | "compact";
  showSeconds?: boolean;
  compactHours?: number;
  showHighestUnitOnly?: boolean;
  zeroUnit?: "H" | "s";
};

export const formatDuration = ({
  milliseconds,
  notation = "standard",
  showSeconds = true,
  compactHours = 1,
  showHighestUnitOnly,
  zeroUnit = "s",
}: FormatDurationProps): string => {
  const dur = dayjs.duration(milliseconds);
  const days = Math.floor(dur.asDays());

  const daysInHours = days * 24;
  const hours = Math.floor(dur.asHours() - daysInHours);

  const daysInMinutes = daysInHours * 60;
  const hoursInMinutes = hours * 60;
  const minutes = Math.floor(dur.asMinutes() - daysInMinutes - hoursInMinutes);

  const daysInSeconds = daysInMinutes * 60;
  const hoursInSeconds = hoursInMinutes * 60;
  const minutesInSeconds = minutes * 60;
  const seconds = Math.floor(dur.asSeconds() - daysInSeconds - hoursInSeconds - minutesInSeconds);

  const compactUnits = [
    { unit: "d", value: days },
    { unit: "H", value: hours },
  ];

  const timeUnits =
    notation === "compact" || dur.asHours() > compactHours
      ? compactUnits
      : [
          { unit: "d", value: days },
          { unit: "H", value: hours },
          { unit: "m", value: minutes },
          ...(showSeconds ? [{ unit: "s", value: seconds }] : []),
        ];

  const formattedArr = timeUnits.filter((u) => u.value > 0).map((u) => `${u.value}${u.unit}`);

  return (showHighestUnitOnly ? formattedArr[0] : formattedArr.join(" ")) || `0${zeroUnit}`;
};

export const formatAxis = (
  value: DatumValue,
  maximumFractionDigits = 4,
  currency?: string,
  isPercent?: boolean,
  minimumFractionDigits?: number,
) =>
  formatAmount(Number(value) || 0, {
    isPercent,
    minimumFractionDigits,
    maximumFractionDigits,
    notation: "compact",
    currency,
  });

export const formatAddressCompact = (address: string, length = 6) =>
  `${address.substring(0, length)}...${address.substring(address.length - length)}`;

export const formatStringCompact = (str: string, length = 15) => {
  if (str.length <= length) return str;
  return `${str.substring(0, length)}...`;
};

export const toTitleCase = (str: string) =>
  str.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
