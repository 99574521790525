import { Box, Paper, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { Link, useParams } from "react-router-dom";
import { PoolTokenIcon } from "src/components/pool-token-icon";
import { RoutePath, RouteParams } from "src/config/routes";
import { Pool } from "src/pages/lp-dashboard-page/generated";

type PoolValueProps = {
  title: string;
  value: string;
};
const PoolValue = ({ title, value }: PoolValueProps) => (
  <Box>
    <Typography variant="caption">{title}</Typography>
    <Typography fontSize={14}>{value}</Typography>
  </Box>
);

type Props = {
  pool: Pool;
};

const SUPERFLUID_APR = 4;

const PoolCard = ({ pool: { id, token1, token2, liquidityUSD, feesUSD7d, incentives } }: Props) => {
  const { clientName } = useParams<{ clientName: string }>();
  const poolPath = RoutePath.LpDashboard.PoolDetails.replace(RouteParams.ClientName, clientName!).replace(
    RouteParams.PoolId,
    id.toString(),
  );

  const aprValue = incentives
    ? formatAmount(incentives.currentIncentiveAPRs.fourteenDays + SUPERFLUID_APR, { isPercent: true })
    : "-";
  const liquidityValue = liquidityUSD ? formatAmount(liquidityUSD, { currency: "USD", notation: "standard" }) : "-";
  const feesValue = feesUSD7d ? formatAmount(feesUSD7d, { currency: "USD", notation: "standard" }) : "-";

  return (
    <Link to={poolPath}>
      <Paper
        variant="card"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <PoolTokenIcon token={token1.symbol} size={64} />
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography fontSize={24} fontWeight={700}>{`${token1.symbol} / ${token2.symbol}`}</Typography>
            <Typography variant="caption">{`Pool #${id}`}</Typography>
          </Box>
          <PoolTokenIcon token={token2.symbol} size={64} />
        </Box>
        <Box display="flex" justifyContent="space-between" width="100%">
          <PoolValue title="Total APR" value={aprValue} />
          <PoolValue title="Liquidity" value={liquidityValue} />
          <PoolValue title="Fees (7D)" value={feesValue} />
        </Box>
      </Paper>
    </Link>
  );
};
export default PoolCard;
