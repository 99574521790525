import { Box, PieChart } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useAuth } from "@frontend/ui/auth";
import { ETHERFI_APPROVED_ROLE } from "src/utils/consts";
import { WithdrawalCards, EventsTable, WithdrawalsCharts } from "./components";
import { EventStrategy, EventTypeFilter, useWithdrawalDistributionByCategoryQuery } from "../../generated";

const Overview = () => {
  const auth = useAuth();
  const { loading, data } = useWithdrawalDistributionByCategoryQuery();
  return (
    <Box>
      <PageHeader showBackButton={false} pageTitle="Withdrawals & Deposits" />
      <Box display="flex" flexDirection="column">
        <WithdrawalCards />
        <WithdrawalsCharts />
        <EventsTable
          title="Withdrawal Queue"
          eventType={EventTypeFilter.WithdrawalRequest}
          strategy={EventStrategy.EethEigenlayer}
          notProcessedRequests
          hideStrategyColumn
          hideAddressColumn
        />
        <EventsTable title="Withdrawal Events" eventType={EventTypeFilter.WithdrawalProcessed} />
        <PieChart
          title="Distribution of Recent Withdrawals"
          isLoading={loading}
          series={[
            {
              data: data?.withdrawalDistributionByCategory.map((w) => [w.category, w.txValue]) ?? [],
            },
          ]}
        />
        {auth.hasRole(ETHERFI_APPROVED_ROLE) && (
          <EventsTable
            title="Deposit Events"
            eventType={EventTypeFilter.Deposit}
            strategy={EventStrategy.EethEigenlayer}
            hideStrategyColumn
          />
        )}
      </Box>
    </Box>
  );
};

export default Overview;
