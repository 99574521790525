import { Box, Grid } from "@frontend/ui";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { WeEthAsCollateral } from "./weEth-as-collateral";
import { AaveV3EtherfiMarketData } from "./aave-v3-etherfi-market-data";

type Props = {
  timeSpan: TimeSpan;
};

export const ChartsGrid = ({ timeSpan }: Props) => (
  <Box>
    <Grid container>
      <WeEthAsCollateral timeSpan={timeSpan} />
      <AaveV3EtherfiMarketData timeSpan={timeSpan} />
    </Grid>
  </Box>
);
