import { PieChart } from "@frontend/ui";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { ChainLiquidityProvider } from "../../../../generated";
import { useProtocolPoolSelection } from "./protocol-pool-selection";

type Props = {
  data?: ChainLiquidityProvider[];
  isLoading: boolean;
};

export const LiquidityProvidersPieChart = ({ data, isLoading }: Props) => {
  const { selectedProtocol, selectedPool } = useProtocolPoolSelection();

  const relevantProviders =
    data?.filter((provider) => provider.protocol === selectedProtocol && provider.pool === selectedPool) ?? [];

  return (
    <PieChart
      title="Liquidity Provider Distribution"
      isLoading={isLoading}
      series={[
        {
          data: relevantProviders.map((provider) => [provider.owner, provider.liquidityUsd] as [string, number]),
        },
      ]}
      formatLegendItemLabel={(address) => (address.startsWith("0x") ? formatAddressCompact(address) : address)}
      getLegendItemTooltip={(address) => address}
      showLabelInEmphasis={false}
      emptyStateText="No liquidity providers found"
      emptyState={relevantProviders.length === 0}
    />
  );
};
