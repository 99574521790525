import { useParams } from "react-router-dom";
import { CompositionOverTimeChart } from "@frontend/ui";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { useLiquidityCompositionOverTimeQuery } from "../../../../generated";
import { useProtocolPoolSelection } from "./protocol-pool-selection";

interface Props {
  timeSpan: TimeSpan;
  isLoadingProtocols: boolean;
}

interface Params extends Record<string, string | undefined> {
  chain?: string;
}
export const LiquidityCompositionOverTimeChart = ({ timeSpan, isLoadingProtocols }: Props) => {
  const { selectedProtocol, selectedPool } = useProtocolPoolSelection();
  const { chain } = useParams<Params>();
  const capitalChain = capitalizeFirstLetter(chain ?? "");
  const { data, loading } = useLiquidityCompositionOverTimeQuery({
    skip: !selectedProtocol || !chain || !selectedPool,
    variables: {
      timeSpan,
      selectedPool: selectedPool!,
      selectedProtocol: selectedProtocol!,
      chain: capitalChain,
    },
  });

  const points = data?.liquidityCompositionOverTime ?? [];
  const denomination = points[0]?.tokenPair;

  return (
    <CompositionOverTimeChart
      title="Liquidity Composition Over Time"
      isLoading={loading}
      cryptoCurrency="weETH"
      percentToggle
      series={[
        {
          type: "bar",
          label: "weETH",
          data: points.map(({ weEthLiquidity, timestamp }) => [timestamp, weEthLiquidity]),
        },
        {
          label: denomination,
          type: "bar",
          data: points.map(({ exitLiquidity, timestamp }) => [timestamp, exitLiquidity]),
        },
      ]}
    />
  );
};
