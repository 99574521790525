import { Paper, Box, Grid, Typography, CustomSwitch } from "@frontend/ui";
import { ChartWidget } from "@frontend/ui/chart-widget";
import { Slider } from "@frontend/ui/slider";
import { palette } from "@frontend/ui/theme/palette";
import { formatAxis, formatAmount, toTitleCase } from "@frontend/ui/utils/formatters";
import { ReactNode, useMemo, useState } from "react";
import { TwapPoolFragment, UniswapChain } from "src/pages/uniswap/generated";
import { debounce } from "@frontend/ui/utils/debounce";
import { calculateCosts, getTwapBlocksByChain } from "./utils";
import { TokenChartTitle } from "./token-chart-title";

type ManipulationCostCardProps = {
  title: string;
  subtitle?: ((blocksControlled: number) => string) | string;
  extraContent?: ReactNode;
  pool: TwapPoolFragment;
  isControlledChain?: boolean;
};

export const ManipulationCostCard = ({
  title,
  subtitle,
  extraContent,
  pool,
  isControlledChain,
}: ManipulationCostCardProps) => {
  const [isValuesInUSD, setIsValuesInUSD] = useState(true);
  const [isAdditionalLiquidityOnSpot, setIsAdditionalLiquidityOnSpot] = useState(true);

  const chain = toTitleCase(pool.chain) as UniswapChain;
  const twapBlocks = getTwapBlocksByChain(chain);

  const [blocksControlled, seBlocksControlled] = useState(isControlledChain ? twapBlocks : undefined);
  const [additionalLiquidity, setAdditionalLiquidity] = useState(isControlledChain ? 0 : undefined);
  const [blocksControlledDebounced, seBlocksControlledDebounced] = useState(isControlledChain ? twapBlocks : undefined);
  const [additionalLiquidityDebounced, setAdditionalLiquidityDebounced] = useState(0);

  const debouncedBlocksControlled = useMemo(() => debounce((val: number) => seBlocksControlledDebounced(val), 200), []);

  const debouncedAdditionalLiquidity = useMemo(
    () => debounce((val: number) => setAdditionalLiquidityDebounced(val), 200),
    [],
  );

  useMemo(() => {
    if (blocksControlled !== undefined) {
      debouncedBlocksControlled(blocksControlled);
    }
  }, [blocksControlled, debouncedBlocksControlled]);

  useMemo(() => {
    if (additionalLiquidity !== undefined) {
      debouncedAdditionalLiquidity(additionalLiquidity);
    }
  }, [additionalLiquidity, debouncedAdditionalLiquidity]);

  const token0Data: [number, number][] = calculateCosts(
    pool.token0.manipulationCosts,
    isValuesInUSD,
    pool.token1.priceUSD,
    chain,
    pool.total_amount_locked_usd,
    blocksControlledDebounced,
    additionalLiquidityDebounced,
    isAdditionalLiquidityOnSpot,
  );
  const token1Data: [number, number][] = calculateCosts(
    pool.token1.manipulationCosts,
    isValuesInUSD,
    pool.token0.priceUSD,
    chain,
    pool.total_amount_locked_usd,
    blocksControlledDebounced,
    additionalLiquidityDebounced,
    isAdditionalLiquidityOnSpot,
  );

  return (
    <Paper
      variant="card"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box>
        <Box display="flex" justifyContent="space-between" gap={1}>
          <Box>
            <Typography variant="h3">{title}</Typography>
            {subtitle && (
              <Typography variant="h6">
                {typeof subtitle === "function" && blocksControlledDebounced
                  ? subtitle(blocksControlledDebounced)
                  : subtitle}
              </Typography>
            )}
          </Box>

          <CustomSwitch
            onChange={() => setIsValuesInUSD((prev) => !prev)}
            checked={isValuesInUSD}
            checkedLabel="USD"
            uncheckedLabel="Token"
            labelsVariant="h4"
          />
        </Box>
        {isControlledChain && (
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={12} lg={3}>
              <Box
                bgcolor="black.main"
                display="flex"
                alignItems="flex-start"
                padding="8px 16px"
                flexDirection="column"
                borderRadius={2}
              >
                <Typography color="almostWhite.main">Controlled Blocks:</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography>0</Typography>
                  <Box width={200}>
                    <Slider
                      value={blocksControlled}
                      min={1}
                      max={twapBlocks}
                      step={1}
                      onChange={(_, val) => {
                        seBlocksControlled(Number(val));
                      }}
                      valueLabelDisplay="auto"
                    />
                  </Box>
                  <Typography>{twapBlocks}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                bgcolor="black.main"
                display="flex"
                alignItems="flex-start"
                padding="8px 16px"
                flexDirection="column"
                borderRadius={2}
              >
                <Typography color="almostWhite.main">Additional Liquidity:</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Box display="flex" alignItems="center">
                      <Typography paddingRight={1}>0</Typography>
                      <Box width={200}>
                        <Slider
                          value={additionalLiquidity}
                          min={0}
                          max={10_000_000}
                          step={100_000}
                          onChange={(_, val) => {
                            setAdditionalLiquidity(Number(val));
                          }}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) =>
                            formatAmount(value, {
                              currency: "USD",
                              minimumFractionDigits: value < 1_000_000 ? 0 : 1,
                            })
                          }
                        />
                      </Box>
                      <Typography paddingLeft={0.5}>${formatAmount(10_000_000)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box display="flex" alignItems="center" paddingTop={0.7}>
                      <CustomSwitch
                        onChange={() => setIsAdditionalLiquidityOnSpot((prev) => !prev)}
                        checked={!isAdditionalLiquidityOnSpot}
                        checkedLabel="Proportional to Liquidity"
                        checkedLabelTooltip="Additional liquidity is spread proportionally to the
                    existing liquidity"
                        uncheckedLabel="Spot Price"
                        uncheckedLabelTooltip="Additional liquidity is evenly distributed between the tokens
                    and added at the current tick"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>

      {extraContent}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper variant="widget">
            <ChartWidget
              series={{
                data: token0Data,
                color: palette.blue.main,
                name: "Cost",
              }}
              type="line"
              title={<TokenChartTitle tokenA={pool.token0.symbol} tokenB={pool.token1.symbol} />}
              currency={isValuesInUSD ? "USD" : undefined}
              tooltipValueFormatter={
                isValuesInUSD ? undefined : (value: number | string) => `${formatAxis(value, 2)} ${pool.token1.symbol}`
              }
              emptyState={token0Data.length <= 1}
              xAxisOptions={{
                type: "category",
                animation: false,
                axisLabel: {
                  formatter: (value: number | string) => formatAmount(Number(value), { isPercent: true }),
                  hideOverlap: true,
                  fontSize: 12,
                },
              }}
              isXAxisPercentage
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper variant="widget">
            <ChartWidget
              series={{
                data: token1Data,
                color: palette.blue.main,
                name: "Cost",
              }}
              type="line"
              title={<TokenChartTitle tokenA={pool.token1.symbol} tokenB={pool.token0.symbol} />}
              emptyState={token1Data.length <= 1}
              currency={isValuesInUSD ? "USD" : undefined}
              tooltipValueFormatter={
                isValuesInUSD ? undefined : (value: number | string) => `${formatAxis(value, 2)} ${pool.token0.symbol}`
              }
              xAxisOptions={{
                type: "category",
                axisLabel: {
                  formatter: (value: number | string) => formatAmount(Number(value), { isPercent: true }),
                  hideOverlap: true,
                  fontSize: 12,
                },
              }}
              isXAxisPercentage
            />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};
