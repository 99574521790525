import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useLocation, useParams } from "react-router-dom";
import { RouteParams, RoutePath } from "src/config/routes";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { useChainOverviewTableQuery } from "../../../generated";

export const ChainsDropdownHeader = () => {
  const { clientName, chain } = useParams();
  const location = useLocation();
  const { data, loading } = useChainOverviewTableQuery();

  const dropdownOptions =
    data?.chainOverviewTable.map(({ chain: chainId }) => ({
      value: chainId,
      label: capitalizeFirstLetter(chainId),
      cryptoIcon: chainId.toLowerCase(),
      link: location.pathname.replace(chain!, chainId),
    })) ?? [];

  return (
    <PageHeader
      backLink={RoutePath.Risk.OnChainLiquidity.replace(RouteParams.ClientName, clientName!)}
      pageTitle={
        <Box>
          {chain && (
            <CustomReactSelect
              options={dropdownOptions}
              value={{
                value: chain,
                label: capitalizeFirstLetter(chain),
                cryptoIcon: chain.toLowerCase(),
              }}
              isSearchable={false}
              variant="h3"
              isLoading={loading}
            />
          )}
        </Box>
      }
      showBackButton
    />
  );
};
