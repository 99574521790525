import { chainCell, ChaosTable, currencyCell, numberCell, percentCell, percentTrendCell } from "@frontend/ui";
import { Header, RenderData } from "@frontend/types";
import { useParams } from "react-router-dom";
import { useChainOverviewTableQuery } from "../../../generated";
import { RouteParams, RoutePath } from "../../../../../config/routes";

export const ChainOverviewTable = () => {
  const { clientName } = useParams();

  const headers: Header[] = [
    { renderType: "TEXT", text: "Chain" },
    { renderType: "TEXT", text: "Supply" },
    { renderType: "TEXT", text: "Dex TVL" },
    { renderType: "TEXT", text: "DEX to SUPPLY RATIO" },
    { renderType: "TEXT", text: "Trading Volume", tooltip: "14-day moving average" },
    { renderType: "TEXT", text: "Liquidity Utilization", tooltip: "14-day moving average" },
    { renderType: "TEXT", text: "eETH to Total Liquidity Ratio" },
    { renderType: "TEXT", text: "1D weETH Liquidity Percentage Change" },
    { renderType: "TEXT", text: "1D Exit Liquidity Percentage Change" },
  ];

  const { data, loading } = useChainOverviewTableQuery();
  const tableData: RenderData[][] = (data?.chainOverviewTable || []).map((row) => [
    chainCell(row.chain, row.chain),
    currencyCell(row.weethSupply, { cryptoCurrency: "ETH" }),
    currencyCell(row.dexTvl, { cryptoCurrency: "ETH" }),
    percentCell(row.dexToSupplyRatio / 100),
    currencyCell(row.tradingVolume14dMovingAvg, { cryptoCurrency: "ETH" }),
    numberCell(row.liquidityUtilization14dMovingAvg),
    percentCell(row.weEthToSupplyRatio / 100),
    percentTrendCell(row.weEth1dPercentageChange / 100),
    percentTrendCell(row.exit1dPercentageChange / 100),
  ]);

  const handleOnClickRow = (rowIdx: number) => {
    const chainData = data?.chainOverviewTable[rowIdx];

    if (!chainData) return "";

    return RoutePath.Risk.OnChainLiquidityDetails.replace(RouteParams.ClientName, clientName!).replace(
      RouteParams.Chain,
      chainData?.chain,
    );
  };

  return (
    <ChaosTable
      title="Chain Overview"
      data={tableData}
      headers={headers}
      isLoading={loading}
      initialSortBy={1}
      isInitialSortDesc
      showRowChevron
      rowHref={handleOnClickRow}
    />
  );
};
