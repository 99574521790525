import { Header } from "@frontend/types";
import { chainCell, ChaosTable, currencyCell, numberCell, textCell } from "@frontend/ui";
import { useMajorPoolsTableQuery } from "../../../generated";

export const MajorPoolsTable = () => {
  const { data, loading } = useMajorPoolsTableQuery();
  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Chain",
    },
    {
      renderType: "TEXT",
      text: "Pool",
    },
    {
      renderType: "TEXT",
      text: "TVL",
    },
    {
      renderType: "TEXT",
      text: "Trading Volume",
      tooltip: "14-day moving average",
    },
    {
      renderType: "TEXT",
      text: "Liquidity Utilization",
      tooltip: "14-day moving average",
    },
  ];

  const tableData = (data?.majorPoolsTable || []).map((row) => [
    chainCell(row.chain, row.chain),
    textCell(row.pool),
    currencyCell(row.tvl, { cryptoCurrency: "ETH" }),
    currencyCell(row.tradingVolume14dMovingAvg, { cryptoCurrency: "ETH" }),
    numberCell(row.liquidityUtilization14dMovingAvg),
  ]);

  return (
    <ChaosTable
      title="Major Major weETH Pools"
      data={tableData}
      headers={headers}
      isLoading={loading}
      initialSortBy={2}
      isInitialSortDesc
    />
  );
};
