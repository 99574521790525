import { Box, CryptoIcon, Grid, Paper, Tooltip, Typography } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { CustomIcon } from "@frontend/ui/custom-icon";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { ReactNode } from "react";
import { RiskExplorerOverviewResults } from "../../../generated";

type Props = {
  data: RiskExplorerOverviewResults;
};

const getNumberDiff = (current: number, previous: number) => {
  const diff = current - previous;

  return diff < 0 ? formatAmount(diff, { notation: "standard" }) : `+${formatAmount(diff, { notation: "standard" })}`;
};

type CardProps = {
  title: string;
  risk: {
    title: string;
    value: string;
  };
  value: number;
  prevValue?: number;
  wallets: number;
  prevWallets?: number;
  stablecoin: {
    token?: string;
    amount: number;
    prevAmount?: number;
  };
  prevPotentialBadDebt?: number;
  totalPotentialBadDebt?: number;
};

const Card = ({
  title,
  risk,
  value,
  prevValue,
  wallets,
  prevWallets,
  stablecoin,
  prevPotentialBadDebt,
  totalPotentialBadDebt,
}: CardProps) => {
  const valueDiff = prevValue !== undefined && value - prevValue;
  const walletsDiff = prevWallets !== undefined && getNumberDiff(wallets, prevWallets);
  const badDebtDiff = prevPotentialBadDebt !== undefined && (totalPotentialBadDebt || 0) - (prevPotentialBadDebt || 0);
  const stablecoinDiff =
    stablecoin?.prevAmount !== undefined && (stablecoin?.amount || 0) - (stablecoin?.prevAmount || 0);
  return (
    <Grid container>
      <Grid xs={12} item>
        <Typography variant="h3" px={4} pb={2}>
          {title}
        </Typography>
      </Grid>
      <Grid xs={12} item borderTop="1px solid" borderRight="1px solid" borderColor="greys.800" py={4} px={4}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Box display="flex" flexDirection="column">
            <Typography variant="label">{risk.title}</Typography>
            <Typography variant="h4">{risk.value}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">Value</Typography>

            <Tooltip
              title={
                valueDiff
                  ? formatAmount(valueDiff, {
                      currency: "USD",
                      notation: "standard",
                    })
                  : "No Change"
              }
              arrow
            >
              <Typography variant="h4" display="inline">
                {!valueDiff || valueDiff >= 0 ? "+" : ""}
                {formatAmount(valueDiff || 0, { currency: "USD" })}
              </Typography>
            </Tooltip>
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
              <Typography variant="subtitle1">Total:</Typography>
              <Typography variant="h5">{formatAmount(value, { currency: "USD" })}</Typography>
            </Box>
          </Box>

          {stablecoin?.token && (
            <Box display="flex" flexDirection="column">
              <Typography variant="label">{stablecoin.token}</Typography>
              <Tooltip title={formatAmount(stablecoinDiff || 0, { notation: "standard" })}>
                <Typography variant="h4" display="inline">
                  {!stablecoinDiff || stablecoinDiff >= 0 ? "+" : ""}
                  {formatAmount(stablecoinDiff || 0, { currency: "USD" })}
                </Typography>
              </Tooltip>
              <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                <Typography variant="subtitle1">Total:</Typography>
                <Typography variant="h5">{formatAmount(stablecoin?.amount, { currency: "USD" })}</Typography>
              </Box>
            </Box>
          )}

          <Box display="flex" flexDirection="column">
            <Typography variant="label">Wallets</Typography>
            <Typography variant="h4">{walletsDiff || "No Change"}</Typography>
            <Box display="flex" flexDirection="row" gap={1} alignItems="center">
              <Typography variant="subtitle1">Total:</Typography>
              <Typography variant="h5">{formatAmount(wallets, { notation: "compact" })}</Typography>
            </Box>
          </Box>

          {totalPotentialBadDebt !== undefined && (
            <Box display="flex" flexDirection="column">
              <Typography variant="label">Bad Debt</Typography>
              <Tooltip title={formatAmount(badDebtDiff || 0, { currency: "USD", notation: "standard" })} arrow>
                <Typography variant="h4">
                  {!badDebtDiff || badDebtDiff >= 0 ? "+" : ""}
                  {formatAmount(badDebtDiff || 0, { currency: "USD" })}
                </Typography>
              </Tooltip>
              <Tooltip
                title="Current and simulated bad debt across all wallets with positions on the changed assets"
                arrow
              >
                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                  <Typography variant="subtitle1">Total:</Typography>
                  <Typography variant="h5">{formatAmount(totalPotentialBadDebt, { currency: "USD" })}</Typography>
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const RiskCards = ({ data }: Props) => {
  const { riskThreshold, eligibleThreshold } = useClientConfig();
  return (
    <Box display="flex" flexDirection={{ xs: "column", lg: "row" }}>
      <Card
        title="Risk of Liquidations"
        risk={{
          title: "Risk",
          value: `Health ${eligibleThreshold}-${riskThreshold}`,
        }}
        value={data.current.totalValueAtRiskUsd}
        prevValue={data.previous?.totalValueAtRiskUsd}
        wallets={data.current.numberOfWalletsAtRisk}
        prevWallets={data.previous?.numberOfWalletsAtRisk}
        stablecoin={{
          amount: data.current.totalNativeStablecoinRiskUsd,
        }}
      />
      <Card
        title="Eligible for Liquidations"
        risk={{
          title: "Eligible",
          value: `health < ${eligibleThreshold}`,
        }}
        value={data.current.totalValueAvailableForLiquidationUsd}
        prevValue={data.previous?.totalValueAvailableForLiquidationUsd}
        wallets={data.current.numberOfWalletsAvailableForLiquidation}
        prevPotentialBadDebt={data.previous?.totalPotentialBadDebtUsd}
        totalPotentialBadDebt={data.current.totalPotentialBadDebtUsd}
        prevWallets={data.previous?.numberOfWalletsAvailableForLiquidation}
        stablecoin={{
          amount: data.current.totalNativeStablecoinAvailableForLiquidationUsd,
          prevAmount: data.previous?.totalNativeStablecoinAvailableForLiquidationUsd,
        }}
      />
    </Box>
  );
};

export default RiskCards;
