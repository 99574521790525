import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { useOnChainLiquidityCardsQuery } from "../../../generated";

export const OnChainLiquidityCards = () => {
  const { data, loading } = useOnChainLiquidityCardsQuery();
  const { totalDexTvl, dexTvlPct, liquidityChange7d, exitLiquidityChange7d } = data?.onChainLiquidityCards || {};
  const parsePercentage = (value?: number) => {
    if (loading) return undefined;
    return value ? Number(value) / 100 : "N/A";
  };
  const parseNumber = (value?: number) => {
    if (loading) return undefined;
    return value ? Number(value) : "N/A";
  };

  const dexTvlPercentage = parsePercentage(dexTvlPct);
  const liquidityChange7dPercentage = parsePercentage(liquidityChange7d);
  const exitLiquidityChange7dPercentage = parsePercentage(exitLiquidityChange7d);
  const totalTvl = parseNumber(totalDexTvl);

  const cards: ValueCardProps[] = [
    {
      title: "Total DEX TVL",
      value: totalTvl,
      cryptoCurrency: "ETH",
    },
    {
      title: "DEX to DeFi TVL Ratio",
      value: dexTvlPercentage,
      isPercent: true,
    },
    {
      title: "weETH Liquidity 7D Percentage Change",
      value: liquidityChange7dPercentage,
      isPercent: true,
      showSign: true,
    },
    {
      title: "Exit Liquidity 7D Percentage Change",
      value: exitLiquidityChange7dPercentage,
      isPercent: true,
      showSign: true,
    },
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={6} lg={3}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
