import { useParams } from "react-router-dom";
import { StackedBarChart, Grid } from "@frontend/ui";
import { useLiquidity1DayPercentChangeBreakdownQuery } from "../../generated";

export const ChainBreakDown1DayPercentageChangeCharts = () => {
  const { chain } = useParams();
  const { data, loading } = useLiquidity1DayPercentChangeBreakdownQuery({
    variables: { chain: chain!.toLocaleLowerCase() },
  });

  const liquidityChange = data?.liquidity1DayPercentChangeBreakdown || [];

  const weEthLiquidityChartData: [string, number][] = [...(liquidityChange || [])]
    .sort((a, b) => a.project.localeCompare(b.project))
    .map((d) => [d.project.toUpperCase(), d.weEthLiquidityPercentageChange1D / 100]);
  const exitLiquidityChartData: [string, number][] = [...(liquidityChange || [])]
    .sort((a, b) => a.project.localeCompare(b.project))
    .map((d) => [d.project.toUpperCase(), d.exitLiquidityPercentageChange1D / 100]);

  return (
    <>
      <Grid item xs={12} lg={6}>
        <StackedBarChart
          chartHeight={325}
          showXAxisLine={false}
          title="weETH Liquidity by Project - 1D Percentage Change"
          isLoading={loading}
          series={[
            {
              label: "Percentage Change",
              data: weEthLiquidityChartData,
            },
          ]}
          showLegend={false}
          isPercent
          showAllXAxisLabels
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <StackedBarChart
          chartHeight={325}
          showXAxisLine={false}
          title="Exit Liquidity by Project - 1D Percentage Change"
          isLoading={loading}
          series={[
            {
              label: "Percentage Change",
              data: exitLiquidityChartData,
            },
          ]}
          showLegend={false}
          isPercent
          showAllXAxisLabels
        />
      </Grid>
    </>
  );
};
