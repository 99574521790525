import { assetCell, Box, ChaosTable, numberCell, percentTrendCell, Tooltip, Typography } from "@frontend/ui";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { Header, RenderType } from "@frontend/types";
import { HoldingsPerToken, useHolderDetailsQuery } from "../../../generated";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Token Name",
  },
  {
    renderType: "TEXT",
    text: "Balance in Token",
  },
  {
    renderType: "TEXT",
    text: "7D Balance Change",
  },
];

const filterOutBadTokens = (token: HoldingsPerToken) =>
  !/^[\\$\\!\\#\\+ ]/.test(token.tokenName) &&
  token.tokenQuantity !== 0 &&
  !/claim|visit/i.test(token.tokenSymbol) &&
  !/\.(com|org|site|net|info|io|edu|gov|biz|co)$/i.test(token.tokenSymbol);

type Props = {
  address: string;
};
export const TopHolderBalancesTable = ({ address }: Props) => {
  const { data, loading } = useHolderDetailsQuery({
    variables: {
      address,
    },
  });
  const { tokenBalances } = data?.holderDetails || {};

  if (tokenBalances === undefined || tokenBalances.length === 0) {
    return <ChaosTable headers={headers} data={[]} isLoading={loading} title="Balances" />;
  }

  const currentTokenBalances = tokenBalances[tokenBalances.length - 1].tokenBalances.filter((token) =>
    filterOutBadTokens(token),
  );

  const weekAgoTokenBalances = tokenBalances[0].tokenBalances.filter((token) => filterOutBadTokens(token));

  const weekChangePercent = (currentTokenBalances ?? []).reduce(
    (acc, token) => {
      const weekAgoToken = (weekAgoTokenBalances ?? []).find(
        (weekToken) => weekToken.tokenAddress.toLowerCase() === token.tokenAddress.toLowerCase(),
      );
      acc[token.tokenSymbol] =
        weekAgoToken && weekAgoToken.tokenQuantity !== 0
          ? (token.tokenQuantity - weekAgoToken.tokenQuantity) / weekAgoToken.tokenQuantity
          : 0;
      return acc;
    },
    {} as Record<string, number>,
  );
  const sortTokensWithIcons = (a: HoldingsPerToken, b: HoldingsPerToken) => {
    // a before b if a has an icon and b does not
    if (getIconSrc(a.tokenSymbol) && !getIconSrc(b.tokenSymbol)) {
      return -1;
    }

    // b before a if b has an icon and a does not
    if (!getIconSrc(a.tokenSymbol) && getIconSrc(b.tokenSymbol)) {
      return 1;
    }

    // otherwise, sort by token balance
    return a.tokenQuantity > b.tokenQuantity ? -1 : 1;
  };
  const sortedToken = currentTokenBalances.sort(sortTokensWithIcons);
  const tableRows = sortedToken.map((token) => [
    assetCell(token.tokenSymbol, token.tokenName),
    numberCell(token.tokenQuantity),
    percentTrendCell(weekChangePercent[token.tokenSymbol]),
  ]);

  return (
    <ChaosTable
      headers={headers}
      data={tableRows}
      isLoading={loading}
      title="Balances"
      description="Displays token balances across all tokens"
      pageSize={10}
      isInitialSortEnable={false}
    />
  );
};
