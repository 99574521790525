import type { EChartsOption } from "echarts";
import { Theme } from "@mui/material";
import { StringChartProps } from "../../types";
import { formatEChartValue } from "../../utils";
import { formatAmount } from "../../../utils/formatters";

export const getPieChartOptions = (
  theme: Theme,
  { series, isPercent, currency, cryptoCurrency }: StringChartProps,
  showLabelInEmphasis = true,
): EChartsOption => ({
  tooltip: {
    trigger: "item",
    valueFormatter: (v) =>
      formatEChartValue({
        isPercent,
        currency,
        cryptoCurrency,
        notation: "standard",
      })(Number(v)),
  },
  legend: {
    show: false,
  },
  series: series.map((s) => {
    const totalValue = s.data.reduce((acc, [, value]) => acc + value, 0);
    return {
      name: s.label,
      type: "pie",
      radius: ["65%", "95%"],
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          color: theme.palette.text.primary,
          formatter: (opt) => {
            const percent = formatAmount(totalValue ? Number(opt.value) / totalValue : 0, { isPercent: true });
            return showLabelInEmphasis ? `${opt.name}\n\n${percent}` : `${percent}`;
          },
        },
      },
      label: {
        show: false,
        position: "center",
      },
      data: [...s.data].sort((a, b) => b[1] - a[1]).map(([name, value]) => ({ name, value })),
    };
  }),
});
