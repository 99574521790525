import { ReactNode } from "react";
import { Box } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { useAuth } from "@frontend/ui/auth";
import { RoutePath } from "../../../../config/routes";
import { RouteParams } from "../../../../config/route-params";
import { CommunityDashboardLayout } from "../../../../components/layouts";
import AvsDropdownHeader from "./avs-dropdown-header";
import NodeOperatorDropdownHeader from "./node-operators-dropdown-header";
import { ETHERFI_APPROVED_ROLE } from "../../../../utils/consts";

type Props = {
  children: ReactNode;
};

const StakingTabsLayout = ({ children }: Props) => {
  const { clientName } = useParams();
  const auth = useAuth();

  const replaceParams = (path: string) => path.replace(RouteParams.ClientName, clientName!);

  const baseTabs = [
    {
      label: "Overview",
      path: replaceParams(RoutePath.Risk.Overview),
    },
    {
      label: "Withdrawals & Deposits",
      path: replaceParams(RoutePath.Risk.Withdrawals),
    },
    {
      label: "AVS",
      path: replaceParams(RoutePath.Risk.Avs),
    },
    {
      label: "Node Operators",
      path: replaceParams(RoutePath.Risk.NodeOperators),
    },
    ...(auth.hasRole(ETHERFI_APPROVED_ROLE)
      ? [
          { label: "Holders", path: replaceParams(RoutePath.Risk.Holders) },
          { label: "On-Chain Liquidity", path: replaceParams(RoutePath.Risk.OnChainLiquidity) },
        ]
      : []),
    {
      label: "Alerts",
      path: replaceParams(RoutePath.Risk.Alerts),
    },
  ];
  const finalTabs = baseTabs.filter((tab): tab is Exclude<typeof tab, undefined> => Boolean(tab));

  return (
    <CommunityDashboardLayout showClientToolsDropdown={false} tabs={finalTabs}>
      {children}
    </CommunityDashboardLayout>
  );
};

export const renderTabsLayout = (children: ReactNode) => <StakingTabsLayout>{children}</StakingTabsLayout>;

export const renderAvsPageLayout = (children: ReactNode) => (
  <StakingTabsLayout>
    <Box display="flex" flexDirection="column">
      <AvsDropdownHeader />
      {children}
    </Box>
  </StakingTabsLayout>
);

export const renderNodeOperatorPageLayout = (children: ReactNode) => (
  <StakingTabsLayout>
    <Box display="flex" flexDirection="column">
      <NodeOperatorDropdownHeader />
      {children}
    </Box>
  </StakingTabsLayout>
);
