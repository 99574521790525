import { ValueOverTimeChart } from "@frontend/ui";
import { getChartPropsFromHistoryData } from "src/utils/echarts";
import { HistoryDataFragment } from "../../../../generated";

type Props = {
  data?: HistoryDataFragment[];
  loading: boolean;
};

export const DailyFeesChart = ({ data, loading }: Props) => {
  const { series } = getChartPropsFromHistoryData(data, "Fees");
  return (
    <ValueOverTimeChart
      title="Daily Fees"
      description="Sum of fees per day from all fee types."
      isLoading={loading}
      series={series}
      showLegend={false}
    />
  );
};
