import { CellStatusType, RenderData } from "@frontend/types";
import { ExternalLinkCell, TokenPairCell, TokenPairProps } from "./custom-cells";
import {
  capitalizeFirstLetter,
  formatAddressCompact,
  formatCsvDate,
  formatDate,
  formatDateAndTime,
  formatDuration,
  FormatDurationProps,
  formatAmount,
} from "../utils/formatters";
import { RoundingMode } from "../utils/types";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { CustomIcon } from "../custom-icon";
import { getAccountDetailsUrl, getTxDetailsUrl } from "../utils/chain-url-mapper";

export const addressCell = (address: string, label?: string, showIdenticon?: boolean): RenderData => ({
  renderType: "TEXT",
  text: label || formatAddressCompact(address),
  tooltipText: address,
  searchTerm: address,
  copyText: address,
  exportText: address,
  identicon: showIdenticon ? address : undefined,
});

export const dateCell = (date?: Date, withSeconds = true): RenderData => ({
  renderType: "TEXT",
  text: formatDateAndTime(date, withSeconds) || "",
  exportText: formatCsvDate(date) || "",
  value: date?.getTime(),
});

export const assetCell = (key: string, text?: string): RenderData => ({
  renderType: "TEXT",
  text: text || key,
  tooltipText: key,
  token1: key,
  extraData: {
    symbol: key,
  },
});

export const currencyCell = (
  value: number | null,
  {
    notation = "compact",
    cryptoCurrency,
  }: {
    maximumFractionDigits?: number;
    notation?: "compact" | "standard";
    cryptoCurrency?: string;
    minValue?: number;
  } = {},
): RenderData => {
  const currency = !cryptoCurrency ? "USD" : undefined;

  return {
    text: formatAmount(value || 0, {
      currency,
      notation: notation || "compact",
    }),
    tooltipText: `${formatAmount(value || 0, {
      currency,
      notation: "standard",
    })}${cryptoCurrency ? ` ${cryptoCurrency}` : ""}`,
    renderType: "TEXT",
    value: Number(value || 0),
    cryptoCurrency,
  };
};

export const labelsCell = (status: CellStatusType, text = "", tooltipText = "", icon?: string): RenderData => ({
  renderType: "LABELS",
  text,
  status,
  tooltipText,
  customIcon: icon,
});

export type ValueType = "currency" | "percent" | "decimal" | "date" | "duration";

export const getTextByValueType = (
  value: number,
  valueType: ValueType,
  notation: "compact" | "standard" = "standard",
) => {
  if (valueType === "date") return formatDate(new Date(value), true);

  if (valueType === "duration") {
    return formatDuration({
      milliseconds: value,
      notation,
    });
  }

  return formatAmount(value || 0, {
    currency: valueType === "currency" ? "USD" : undefined,
    isPercent: valueType === "percent" ? true : undefined,
    notation,
  });
};

export const valueCell = (
  value: number,
  valueType: ValueType = "decimal",
  textNotation: "compact" | "standard" = "compact",
): RenderData => ({
  renderType: "TEXT",
  text: getTextByValueType(value, valueType, textNotation),
  tooltipText: getTextByValueType(value, valueType, "standard"),
  value,
});

export const durationCell = (props: FormatDurationProps): RenderData => ({
  renderType: "TEXT",
  text: formatDuration(props),
  value: props.milliseconds,
});

export const valueLabelsCell = (
  status: CellStatusType,
  value: number,
  valueType: ValueType = "decimal",
): RenderData => ({
  renderType: "LABELS",
  text: getTextByValueType(value, valueType, "compact"),
  tooltipText: getTextByValueType(value, valueType, "standard"),
  status,
  value,
});

export const iconsCell = (
  icons: string[],
  text = icons.join(", "),
  tooltipText = "",
  textSuffix = "",
  valueToIcon?: Record<string, string>,
  disableWrappedSymbol?: boolean,
): RenderData => ({
  renderType: "ICONS",
  text,
  tooltipText,
  icons,
  textSuffix,
  valueToIcon,
  disableWrappedSymbol,
});

export const chipCell = (
  value: number,
  chipColor?: string,
  textColor?: string,
  notation: "compact" | "standard" = "compact",
): RenderData => ({
  renderType: "CHIP",
  text: formatAmount(value, { notation }),
  chipColor,
  textColor,
  value,
  tooltipText: formatAmount(value, { notation: "standard" }),
});

export const chainCell = (key?: string, text = "", tooltipText = ""): RenderData => ({
  renderType: "TEXT",
  text: capitalizeFirstLetter(text),
  tooltipText: capitalizeFirstLetter(tooltipText),
  token1: key,
  extraData: {
    symbol: key,
  },
});

interface NumberCellOptions {
  tokenIcon?: string;
  isPercent?: boolean;
  roundingMode?: RoundingMode;
  maximumFractionDigitsForTooltip?: number;
}

export const numberCell = (value: number | null, options?: NumberCellOptions): RenderData => ({
  text: formatAmount(value || 0, {
    notation: "compact",
    isPercent: options?.isPercent,
    roundingMode: options?.roundingMode,
  }),
  tooltipText: formatAmount(value || 0, {
    notation: "standard",
    isPercent: options?.isPercent,
    roundingMode: options?.roundingMode,
  }),
  renderType: "TEXT",
  value: value || 0,
  token1: options?.tokenIcon,
});

export const apyCell = (value: number | null): RenderData => ({
  text: formatAmount(value || 0, { isPercent: true, notation: "compact" }),
  renderType: "TEXT",
  value: value ? Number(value * 100) : 0,
  tooltipText: formatAmount(value || 0, { isPercent: true, notation: "compact" }),
});

export const percentCell = (
  value: number | null,
  tooltipText?: string,
  renderType: "TEXT" | "CHIP" = "TEXT",
): RenderData => ({
  text: formatAmount(value || 0, { isPercent: true, notation: "compact" }),
  renderType,
  value: value ? Number(value * 100) : 0,
  tooltipText,
});

export const progressCell = (value: number | null): RenderData => ({
  renderType: "PROGRESS",
  progressValue: value || 0,
  width: 150,
  text: `${value || 0}`,
  value: (value || 0) * 100,
});

export const textCell = (
  text: string,
  value?: number,
  tokenIcon?: string,
  tooltip?: string,
  icon?: string,
  imgSrc?: string,
): RenderData => ({
  text,
  tooltipText: tooltip || text,
  renderType: "TEXT",
  value,
  token1: tokenIcon,
  customIcon: icon,
  imgSrc,
});

export const markedCell = (isMarked: boolean, label?: string, tooltip?: string): RenderData => ({
  renderType: "TEXT",
  text: label ? (isMarked ? `With ${label}` : `Without ${label}`) : "",
  isMarked,
  tooltipText: tooltip,
});

export const tokenPairCell = (props: TokenPairProps): RenderData => ({
  renderType: "CUSTOM",
  text: `${props.token1}/${props.token2}`,
  component: <TokenPairCell {...props} />,
});

export const linkCell = (text: string, href: string, tooltip?: string): RenderData => ({
  renderType: "LINK",
  text,
  href,
  tooltipText: tooltip || text,
  exportText: tooltip || text,
});

export const diffCell = (value: number, prevValue: number, valueType: ValueType = "decimal"): RenderData => ({
  renderType: "RECOMMENDATION",
  text: getTextByValueType(value, valueType, "compact"),
  previousText: getTextByValueType(prevValue, valueType, "compact"),
  tooltipText: getTextByValueType(value, valueType, "standard"),
  value,
});

export const percentTrendCell = (value: number): RenderData => {
  const formattedStandard = formatAmount(value || 0, { isPercent: true, notation: "standard" });
  const formattedCompact = formatAmount(value || 0, { isPercent: true, notation: "compact" });
  let color = "main.white";
  if (value > 0) {
    color = "success.main";
  } else if (value < 0) {
    color = "error.main";
  }
  let trendIndicator: "" | JSX.Element = "";
  if (value > 0) {
    trendIndicator = <CustomIcon icon="sort-up" />;
  } else if (value < 0) {
    trendIndicator = <CustomIcon icon="sort-down" />;
  }
  return {
    renderType: "CUSTOM",
    text: formattedStandard,
    component: (
      <Tooltip title={formattedStandard} arrow placement="bottom-start">
        <Typography color={color} display="flex" alignItems="center" whiteSpace="nowrap" variant="h5">
          {trendIndicator}
          {formattedCompact}
        </Typography>
      </Tooltip>
    ),
    value,
  };
};

export const externalLinkCell = (
  value: string,
  href: string,
  formatDisplayValue?: (value: string) => string,
): RenderData => ({
  renderType: "CUSTOM",
  text: value,
  copyText: value,
  searchTerm: value,
  exportText: value,
  component: <ExternalLinkCell text={formatDisplayValue?.(value) ?? value} tooltip={value} href={href} />,
});

export const externalLinkAddressCell = (address: string, chain: string, addressType: "account" | "tx"): RenderData =>
  externalLinkCell(address, (addressType === "tx" ? getTxDetailsUrl : getAccountDetailsUrl)(chain, address), (addr) =>
    formatAddressCompact(addr, 4),
  );
