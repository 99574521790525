import { ComponentProps, ReactNode } from "react";
import { Box } from "../box";
import { Paper } from "../paper";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { CustomIcon } from "../custom-icon";
import { formatAmount } from "../utils/formatters";
import { ShimmerLoader } from "../shimmer-loader";
import { Trend } from "../trend";
import { ProgressBar } from "../progress-bar";

export type ValueCardProps = {
  id?: string;
  title: string;
  titleColor?: string;
  titleVariant?: ComponentProps<typeof Typography>["variant"];
  value?: number | string | ValueCardProps[] | ReactNode | null;
  valueMaximumFractionDigits?: number;
  showFullValue?: boolean;
  isPercent?: boolean;
  isDeltaPercent?: boolean;
  currency?: string;
  delta?: number;
  deltaTrend?: number;
  deltaColor?: number;
  tooltip?: string;
  bgColor?: string;
  deltaTooltip?: string;
  progress?: {
    maxValue: number;
    displayMaxValue?: boolean;
  };
  onClick?: () => void;
  showSign?: boolean;
  cryptoCurrency?: string;
  imgSrc?: string;
  disableContainerSpaceBetween?: boolean;
  extra?: ReactNode;
};

const ValueCardInner = ({
  id,
  title,
  titleColor,
  titleVariant = "label",
  value,
  showFullValue,
  isPercent,
  isDeltaPercent,
  currency,
  delta,
  deltaTrend,
  deltaColor,
  tooltip,
  deltaTooltip,
  progress,
  valueMaximumFractionDigits,
  onClick,
  showSign,
  cryptoCurrency,
  imgSrc,
  extra,
}: ValueCardProps) => (
  <>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" gap={1}>
        <Typography variant={titleVariant} color={titleColor}>
          {title}
        </Typography>
        <Box display="flex" gap={[0.5, 1]}>
          {tooltip && (
            <Box>
              <Tooltip title={tooltip} arrow>
                <CustomIcon icon="info" />
              </Tooltip>
            </Box>
          )}
          {onClick && <CustomIcon icon="chevron-right" />}
        </Box>
      </Box>
      {extra}
    </Box>
    <Box>
      {value === undefined && <ShimmerLoader sx={{ borderRadius: 1, height: 32 }} />}
      {Array.isArray(value) && (
        <Box display="flex" gap={5}>
          {value.map((props) => (
            <Box>
              <ValueCardInner {...props} />
            </Box>
          ))}
        </Box>
      )}
      {!Array.isArray(value) && value !== undefined && (
        <Box display="flex" gap={1} flexWrap="wrap" alignItems="center">
          {imgSrc && <img src={imgSrc} alt={imgSrc} width={24} height={24} />}
          <Tooltip
            title={
              typeof value === "number"
                ? `${showSign && value > 0 ? "+" : ""}${formatAmount(value || 0, {
                    isPercent,
                    currency,
                    notation: "standard",
                  })}${cryptoCurrency ? ` ${cryptoCurrency}` : ""}`
                : ""
            }
            arrow
            placement="bottom"
          >
            <Box display="flex" gap={1} alignItems="baseline">
              <Typography
                variant="h3"
                color={typeof value === "number" && showSign ? (value > 0 ? "success.main" : "error.main") : undefined}
                data-testid={id ? `value-card-${id}` : undefined}
              >
                {typeof value === "number"
                  ? formatAmount(value, {
                      currency,
                      notation: showFullValue ? "standard" : "compact",
                      isPercent,
                      maximumFractionDigits: valueMaximumFractionDigits,
                      showSign,
                    })
                  : value}
              </Typography>
              {cryptoCurrency && <Typography>{cryptoCurrency}</Typography>}
            </Box>
          </Tooltip>
          {progress?.displayMaxValue && (
            <Tooltip
              title={formatAmount(progress.maxValue, { isPercent, currency, notation: "standard" })}
              arrow
              placement="bottom"
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="h5" color="greys.400">
                  of
                </Typography>
                <Typography variant="h3">
                  {formatAmount(progress.maxValue, {
                    currency,
                    notation: showFullValue ? "standard" : "compact",
                    isPercent,
                    maximumFractionDigits: valueMaximumFractionDigits,
                    showSign,
                  })}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {delta !== undefined && deltaTrend !== undefined && (
            <Trend
              delta={delta}
              deltaTrend={deltaTrend}
              deltaColor={deltaColor}
              isPercent={isDeltaPercent || isPercent}
              currency={currency}
              tooltip={deltaTooltip}
              maximumFractionDigits={valueMaximumFractionDigits}
            />
          )}
        </Box>
      )}
    </Box>
    {progress && typeof value === "number" && (
      <Box>
        <ProgressBar progress={Number(value) / progress.maxValue} textPosition="right" bgBlack />
      </Box>
    )}
  </>
);

const ValueCard = (props: ValueCardProps) => {
  const { onClick, disableContainerSpaceBetween } = props;

  return (
    <Paper
      variant="card"
      sx={{
        cursor: onClick ? "pointer" : undefined,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        height: "100%",
        justifyContent: disableContainerSpaceBetween ? undefined : "space-between",
      }}
      onClick={onClick}
    >
      <ValueCardInner {...props} />
    </Paper>
  );
};

export default ValueCard;
