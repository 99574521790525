import { Box, ECharts, Typography, echartsTooltipFormatter, useTheme } from "@frontend/ui";
import { formatAmount, formatDate, formatDateAndTime } from "@frontend/ui/utils/formatters";
import { palette } from "@frontend/ui/theme/palette";
import { LpStrategyTimeSeriesData } from "src/pages/uniswap/generated";
import dayjs from "dayjs";

type Props = {
  quoteToken: string;
  data: LpStrategyTimeSeriesData;
};

const PositionBoundsChart = ({ quoteToken, data }: Props) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={1} height="100%">
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h4">Position Bounds</Typography>
        <Box height={300} position="relative">
          <ECharts
            option={{
              dataZoom: {
                type: "inside",
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
                formatter: echartsTooltipFormatter({
                  theme,
                  headerFormatter: (value) => formatDateAndTime(new Date(Number(value)), false),
                  valueFormatter: (val) =>
                    `${formatAmount(Number(val), {
                      notation: "standard",
                    })} ${quoteToken}`,
                }),
                padding: 0,
                renderMode: "auto",
                verticalAlign: "middle",
              },
              xAxis: {
                type: "time",
                axisLabel: {
                  formatter: (value: number) => {
                    if (dayjs(Number(value)).hour() === 0) {
                      return formatDate(new Date(Number(value)));
                    }

                    return "";
                  },
                  rotate: 0,
                  fontSize: 14,
                  color: palette.almostWhite.main,
                },
              },
              yAxis: {
                type: "value",
                axisLabel: {
                  formatter: (value: number) =>
                    `${formatAmount(Number(value), {
                      notation: Number(value) > 0.00001 ? "compact" : "engineering",
                    })} ${quoteToken}`,
                  fontSize: 12,
                },
                splitNumber: 3,
                min: data.prices.reduce((acc, d) => Math.min(d.value, acc), Infinity) * 0.99,
                max: data.prices.reduce((acc, d) => Math.max(d.value, acc), 0) * 1.01,
              },
              series: [
                {
                  name: "Price",
                  type: "line",
                  color: palette.orange.main,
                  showSymbol: false,
                  lineStyle: {
                    width: 1,
                  },
                  data: data.prices.map((datapoint) => [datapoint.time * 1000, datapoint.value]),
                  markArea: {
                    itemStyle: {
                      color: palette.orange.opacity80,
                    },
                    data: data.position_bounds.map((item) => [
                      {
                        xAxis: item.startTime * 1000,
                        yAxis: item.min,
                      },
                      {
                        xAxis: item.endTime * 1000,
                        yAxis: item.max,
                      },
                    ]),
                  },
                },
              ],
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PositionBoundsChart;
