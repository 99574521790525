import { CellStatusType } from "@frontend/types";
import { AlertSeverity } from "../pages/ccar-lending-page/generated";

export const CHAOS_LABS_MEMBER_ROLE = "chaos-labs-member";
export const ETHERFI_APPROVED_ROLE = "etherfi-approved-member";

export const alertSeverityStatus: Record<AlertSeverity, CellStatusType> = {
  HIGH: "ERROR",
  INFO: "INFO",
};

export const alertSeverityLabels: Record<AlertSeverity, string> = {
  HIGH: "High",
  INFO: "Info",
};

export const alertTypeLabels: Record<string, string> = {
  BORROW_CAPS: "Borrow Cap",
  BORROW: "Borrow",
  LIQUIDATIONS: "Liquidations",
  LIQUIDITY: "Liquidity",
  SUPPLY: "Supply",
  PRICE_CHANGE: "Price Change",
  STABLE_PEG: "Stable Peg",
  ASSET_PEG: "Asset Peg",
  SUPPLY_CAPS: "Supply Caps",
  UTILIZATIONS: "Utilization",
  VALUE_AT_RISK: "Value at Risk",
  WHALE: "Whale Alert",
  BAD_DEBT: "Bad Debt",
  DEBT_CEILING: "Debt Ceiling",
  HEALTH_CHECK: "Health Check",
  POSITION_SIZE: "Position Size",
  LEVERAGE: "Leverage",
  FUNDING_RATE: "Funding Rate",
  CONFIG_CHANGE: "Config Change",
  CIRCULATING_SUPPLY: "Circulating Supply",
  TVL: "Total Value Locked",
  PNL: "Profit & Loss",
};
