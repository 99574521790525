import { ValueOverTimeChart } from "@frontend/ui";
import { NumberChartSeries } from "@frontend/ui/echarts/types";
import { TimeSpanPicker } from "src/pages/ccar-lending-page/components/time-span-picker";
import {
  PriceFeedTimeSpan,
  priceFeedTimeSpanOptions,
  useProvidersSelectContext,
} from "src/pages/oracle-portal/context/providers-select-context";
import { Endpoints } from "../../../api";
import { useFeedData } from "../use-price-feed-details";

const PriceHistoryChart = () => {
  const { providerNameById } = useProvidersSelectContext();
  const { response: prices, isLoading, timeSpan, setTimeSpan } = useFeedData(Endpoints.PriceFeedPrices);

  const series = (prices ? [...prices] : [])
    .sort((a, b) => (a.type < b.type ? 1 : -1))
    .reduce<Record<string, NumberChartSeries>>((acc, point) => {
      const pointId = `${point.origin}-${point.type}`;
      const currentSeriesData = acc[pointId]?.data || [];
      const newSeries: NumberChartSeries = {
        id: pointId,
        label: providerNameById?.[point.id] || point.origin,
        data: [...currentSeriesData, [point.timestamp * 1000, point.price]] as [number, number][],
        type: "line",
      };
      return { ...acc, [pointId]: newSeries };
    }, {});

  return (
    <ValueOverTimeChart
      key={`prices-chart-${Object.keys(series).join("-")}`}
      title="Price History in USD"
      description="The historical price movements of the asset"
      series={Object.values(series)}
      currency="USD"
      isLoading={isLoading}
      yAxisMax
      yAxisMin
      zoomable
      timeSpan={timeSpan}
      headerSuffix={
        <TimeSpanPicker<PriceFeedTimeSpan>
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={setTimeSpan}
          timeSpanOptions={priceFeedTimeSpanOptions}
          hideLabel
        />
      }
    />
  );
};

export default PriceHistoryChart;
