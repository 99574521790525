import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { DexBreakdownChart } from "./dex-breakdown-charts";
import { useChainDexBreakdownDataOverTimeQuery } from "../../../generated";
import { ChainBreakDown1DayPercentageChangeCharts } from "../day-percentage-change";

export const ChainOverviewCharts = () => {
  const { chain } = useParams();

  const { data, loading } = useChainDexBreakdownDataOverTimeQuery({
    variables: {
      chain: chain!,
      timeSpan: TimeSpan.Month,
    },
  });

  return (
    <Grid container>
      <Grid item xs={6}>
        <DexBreakdownChart
          title="weETH Liquidity by DEX"
          dataField="liquidityInEth"
          data={data?.chainDexBreakdownDataOverTime}
          isLoading={loading}
          cryptoCurrency="ETH"
          showArea
        />
      </Grid>
      <Grid item xs={6}>
        <DexBreakdownChart
          title="Exit Liquidity by DEX"
          dataField="exitLiquidityInEth"
          data={data?.chainDexBreakdownDataOverTime}
          isLoading={loading}
          cryptoCurrency="ETH"
          showArea
        />
      </Grid>
      <ChainBreakDown1DayPercentageChangeCharts />
      <Grid item xs={6}>
        <DexBreakdownChart
          title="DEX 14D Moving Average Liquidity Utilization"
          dataField="utilization14dMovingAvg"
          data={data?.chainDexBreakdownDataOverTime}
          isLoading={loading}
          disableStacked
          currency=""
          hideTotal
        />
      </Grid>
      <Grid item xs={6}>
        <DexBreakdownChart
          title="DEX Share of Total TVL"
          dataField="exitPercentDeFi"
          data={data?.chainDexBreakdownDataOverTime}
          isLoading={loading}
          isPercent
          disableStacked
          hideTotal
        />
      </Grid>
    </Grid>
  );
};
