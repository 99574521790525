import { Header, RenderData } from "@frontend/types";
import { ChaosTable } from "@frontend/ui";
import { formatAmount } from "@frontend/ui/utils/formatters";
import { FC } from "react";
import { Chain, CollateralizedWallet } from "src/pages/ccar-lending-page/generated";
import { getChainDisplayName } from "../../alerts/utils";

type Props = {
  wallets: CollateralizedWallet[];
  riskThreshold: number;
  eligibleThreshold: number;
  isLoading?: boolean;
};

export const ChainsRiskTable: FC<Props> = ({ wallets, riskThreshold, eligibleThreshold, isLoading }) => {
  const riskPerChain = wallets.reduce<
    Partial<
      Record<
        Chain,
        {
          walletsAtRisk: number;
          usdAtRisk: number;
          walletsEligible: number;
          usdEligible: number;
        }
      >
    >
  >((acc, wallet) => {
    const chain = wallet.chainHealth?.[0].chain;
    return chain
      ? {
          ...acc,
          [chain]: {
            walletsAtRisk:
              wallet.health < Number(riskThreshold) && wallet.health >= Number(eligibleThreshold)
                ? (acc[chain]?.walletsAtRisk || 0) + 1
                : acc[chain]?.walletsAtRisk || 0,
            usdAtRisk:
              wallet.health < Number(riskThreshold) && wallet.health >= Number(eligibleThreshold)
                ? (acc[chain]?.usdAtRisk || 0) + Number(wallet.valueEligibleForLiquidation)
                : acc[chain]?.usdAtRisk || 0,
            walletsEligible:
              wallet.health < Number(eligibleThreshold)
                ? (acc[chain]?.walletsEligible || 0) + 1
                : acc[chain]?.walletsEligible || 0,
            usdEligible:
              wallet.health < Number(eligibleThreshold)
                ? (acc[chain]?.usdEligible || 0) + Number(wallet.valueEligibleForLiquidation)
                : acc[chain]?.usdEligible || 0,
          },
        }
      : acc;
  }, {});
  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Chain",
    },
    {
      renderType: "TEXT",
      text: "Risk of Liquidation",
    },
    {
      renderType: "TEXT",
      text: "Risk of Liquidation",
    },
    {
      renderType: "TEXT",
      text: "Eligible for Liquidation",
    },
    {
      renderType: "TEXT",
      text: "Eligible for Liquidation",
    },
  ];
  const data: RenderData[][] = Object.entries(riskPerChain).map(([chain, risk]) => [
    {
      renderType: "TEXT",
      text: getChainDisplayName(chain as Chain),
      tooltipText: getChainDisplayName(chain as Chain),
      token1: chain,
    },
    {
      renderType: "TEXT",
      text: formatAmount(risk.usdAtRisk, { currency: "USD" }),
      tooltipText: formatAmount(risk.usdAtRisk, { currency: "USD", notation: "standard" }),
      value: risk.usdAtRisk,
    },
    {
      renderType: "TEXT",
      text: `${formatAmount(risk.walletsAtRisk)} Wallets`,
      value: risk.walletsAtRisk,
    },
    {
      renderType: "TEXT",
      text: formatAmount(risk.usdEligible, { currency: "USD" }),
      tooltipText: formatAmount(risk.usdEligible, { currency: "USD", notation: "standard" }),
      value: risk.usdEligible,
    },
    {
      renderType: "TEXT",
      text: `${formatAmount(risk.walletsEligible)} Wallets`,
      value: risk.walletsEligible,
    },
  ]);

  return (
    <ChaosTable
      title="Value at Risk of Liquidation"
      description="Per chain aggregation of value that will be liquidated in the simulated scenario."
      headers={headers}
      data={data}
      isLoading={isLoading}
      isFilterHidden
    />
  );
};
