import { Box, CustomReactSelect, OptionType } from "@frontend/ui";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

interface ProtocolPoolSelectionContextType {
  selectedProtocol: string | null;
  selectedPool: string | null;
  setSelectedProtocol: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedPool: React.Dispatch<React.SetStateAction<string | null>>;
}

const ProtocolPoolSelectionContext = createContext<ProtocolPoolSelectionContextType | undefined>(undefined);

export const ProtocolPoolSelectionProvider = ({ children }: { children: ReactNode }) => {
  const [selectedProtocol, setSelectedProtocol] = useState<string | null>(null);
  const [selectedPool, setSelectedPool] = useState<string | null>(null);

  const contextValue = useMemo(
    () => ({ selectedProtocol, selectedPool, setSelectedProtocol, setSelectedPool }),
    [selectedProtocol, selectedPool, setSelectedProtocol, setSelectedPool],
  );

  return <ProtocolPoolSelectionContext.Provider value={contextValue}>{children}</ProtocolPoolSelectionContext.Provider>;
};

export const useProtocolPoolSelection = () => {
  const context = useContext(ProtocolPoolSelectionContext);
  if (!context) {
    throw new Error("ProtocolPoolSelectionContext must be used within a ChatSettingsProvider");
  }
  return context;
};

export type PoolsByProtocol = {
  [protocol: string]: string[];
};

type ProtocolPoolSelectionProps = {
  poolsByProtocol: PoolsByProtocol;
  isLoading: boolean;
};

export const ProtocolPoolSelection = ({ poolsByProtocol, isLoading }: ProtocolPoolSelectionProps) => {
  const { selectedProtocol, selectedPool, setSelectedProtocol, setSelectedPool } = useProtocolPoolSelection();

  useEffect(() => {
    const firstProtocol = Object.keys(poolsByProtocol)[0];
    const firstPool = poolsByProtocol[firstProtocol]?.[0];

    setSelectedProtocol(firstProtocol || null);
    setSelectedPool(firstPool || null);
  }, [poolsByProtocol, setSelectedProtocol, setSelectedPool]);

  const protocolOptions: OptionType[] = Object.keys(poolsByProtocol).map((protocol) => ({
    cryptoIcon: protocol,
    label: protocol,
    value: protocol,
  }));

  const poolOptions = selectedProtocol
    ? poolsByProtocol[selectedProtocol]?.map((pool) => ({
        label: pool,
        value: pool,
      })) || []
    : [];

  return (
    <Box display="flex" gap={2}>
      <CustomReactSelect
        options={protocolOptions}
        value={protocolOptions.find((opt) => opt.value === selectedProtocol)}
        isSearchable={false}
        placeholder="Select Protocol"
        isLoading={isLoading}
        onChange={(opt) => {
          const protocol = opt?.value || null;
          setSelectedProtocol(protocol);
          const firstPool = protocol ? poolsByProtocol[protocol]?.[0] : null;
          setSelectedPool(firstPool);
        }}
        disabled={isLoading || !protocolOptions.length}
      />
      <CustomReactSelect
        options={poolOptions}
        value={poolOptions.find((opt) => opt.value === selectedPool)}
        isSearchable={false}
        placeholder="Select Pool"
        isLoading={isLoading}
        onChange={(opt) => setSelectedPool(opt?.value || null)}
        isDisabled={!selectedProtocol || isLoading || !poolOptions.length}
      />
    </Box>
  );
};
