import { Box, CustomReactSelect } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { formatAddressCompact } from "@frontend/ui/utils/formatters";
import { useLocation, useParams } from "react-router-dom";
import { useStakingTopHoldersQuery } from "../../generated";
import { RouteParams, RoutePath } from "../../../../config/routes";
import { TopHolderCards } from "./components/top-holder-cards";
import { TopHolderBalancesTable } from "./components/top-holder-balances-table";
import TopHolderHoldingsCharts from "./components/top-holder-holdings-charts";
import { TopHolderEventsTable } from "./components/top-holder-events-table";

export const TopHolderDetails = () => {
  const { data, loading } = useStakingTopHoldersQuery();
  const { topHolderAddress, clientName } = useParams();
  const location = useLocation();

  const dropdownOptions = [...(data?.stakingTopHolders || [])]
    .sort((a, b) => a.address.localeCompare(b.address))
    .map(({ address }) => ({
      value: address,
      label: address,
      link: location.pathname.replace(topHolderAddress!, address),
    }));

  const selectedHolder = dropdownOptions.find((opt) => opt.value === topHolderAddress);

  return (
    <Box>
      <PageHeader
        backLink={RoutePath.Risk.Holders.replace(RouteParams.ClientName, clientName!)}
        pageTitle={
          <CustomReactSelect
            options={dropdownOptions}
            value={
              selectedHolder ? { ...selectedHolder, label: formatAddressCompact(selectedHolder.label) } : undefined
            }
            variant="h3"
            isLoading={loading}
          />
        }
      />
      <TopHolderCards address={topHolderAddress!} />
      <TopHolderHoldingsCharts address={topHolderAddress!} />
      <TopHolderBalancesTable address={topHolderAddress!} />
      <TopHolderEventsTable address={topHolderAddress!} />
    </Box>
  );
};
