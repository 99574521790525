import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { Grid, CompositionOverTimeChart, ValueOverTimeChart } from "@frontend/ui";
import { useAaveEtherfiMarketDataQuery } from "../../../generated";

type Props = {
  timeSpan: TimeSpan;
};

export const AaveV3EtherfiMarketData = ({ timeSpan }: Props) => {
  const { loading, data } = useAaveEtherfiMarketDataQuery({ variables: { timeSpan } });

  const series = data?.aaveEtherfiMarketData?.reduce(
    (acc, item) => {
      const { symbol, totalBorrowUsd, totalSupplyUsd, utilizationPercent, timestamp } = item ?? {};
      if (!symbol || !totalSupplyUsd || !timestamp) {
        return acc;
      }
      if (acc.supplyPerAssetOverTimeSeries[symbol] === undefined) {
        acc.supplyPerAssetOverTimeSeries[symbol] = { data: [], label: symbol };
      }
      if (
        totalBorrowUsd !== null &&
        totalBorrowUsd !== undefined &&
        acc.borrowPerAssetOverTimeSeries[symbol] === undefined
      ) {
        acc.borrowPerAssetOverTimeSeries[symbol] = { data: [], label: symbol };
      }
      if (
        utilizationPercent !== null &&
        utilizationPercent !== undefined &&
        acc.utilizationPerAssetOverTimeSeries[symbol] === undefined
      ) {
        acc.utilizationPerAssetOverTimeSeries[symbol] = { data: [], label: symbol };
      }

      acc.supplyPerAssetOverTimeSeries[symbol].data.push([timestamp, totalSupplyUsd]);
      if (totalBorrowUsd !== null && totalBorrowUsd !== undefined) {
        acc.borrowPerAssetOverTimeSeries[symbol].data.push([timestamp, totalBorrowUsd]);
      }

      if (utilizationPercent !== null && utilizationPercent !== undefined) {
        acc.utilizationPerAssetOverTimeSeries[symbol].data.push([timestamp, utilizationPercent]);
      }
      return acc;
    },
    {
      supplyPerAssetOverTimeSeries: {} as Record<string, NumberChartSeries>,
      borrowPerAssetOverTimeSeries: {} as Record<string, NumberChartSeries>,
      utilizationPerAssetOverTimeSeries: {} as Record<string, NumberChartSeries>,
    },
  );

  return (
    <>
      <Grid item xs={12} lg={6}>
        <CompositionOverTimeChart
          title="Aave V3 EtherFi Market Supply"
          description="Total Supply by token on the EtherFi market on Aave V3"
          isLoading={loading}
          series={Object.values(series?.supplyPerAssetOverTimeSeries ?? {})}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CompositionOverTimeChart
          title="Aave V3 EtherFi Market Borrow"
          description="Total Borrow by token on the EtherFi market on Aave V3"
          isLoading={loading}
          series={Object.values(series?.borrowPerAssetOverTimeSeries ?? {})}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ValueOverTimeChart
          title="Aave V3 EtherFi Market Utilization"
          description="Utilization Rate (Borrow / Supply) per token on the EtherFi market on Aave V3"
          isLoading={loading}
          series={Object.values(series?.utilizationPerAssetOverTimeSeries ?? {})}
          isPercent
        />
      </Grid>
    </>
  );
};
