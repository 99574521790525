import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "@frontend/ui/utils/formatters";
import { Box, Typography } from "@frontend/ui";
import { useMemo } from "react";
import { TimeSpan } from "@frontend/ui/echarts/types";
import { LiquidityProvidersPieChart } from "./liquidity-providers-pie-chart";
import { PoolsByProtocol, ProtocolPoolSelection, ProtocolPoolSelectionProvider } from "./protocol-pool-selection";
import { ChainLiquidityProvider, useChainLiquidityProvidersByDexQuery } from "../../../../generated";
import { WeEthExchangeRateOverTimeChart } from "./weeth-exchange-rate-chart";
import { LiquidityCompositionOverTimeChart } from "./liquidity-composition-overtime";

const groupPoolsByProtocol = (data: ChainLiquidityProvider[]): PoolsByProtocol => {
  const result: PoolsByProtocol = {};

  data.forEach(({ protocol, pool }) => {
    if (!result[protocol]) {
      result[protocol] = [];
    }
    if (!result[protocol].includes(pool)) {
      result[protocol].push(pool);
    }
  });

  return result;
};

export const LiquiditySection = () => {
  const { chain } = useParams();
  const { data, loading } = useChainLiquidityProvidersByDexQuery({
    variables: {
      chain: capitalizeFirstLetter(chain!),
    },
  });

  const poolsByProtocol = useMemo(
    () => groupPoolsByProtocol(data?.chainLiquidityProvidersByDex || []),
    [data?.chainLiquidityProvidersByDex],
  );

  return Object.keys(poolsByProtocol).length > 0 ? (
    <ProtocolPoolSelectionProvider>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" pt={5} pb={3} px={3}>
          <Typography variant="h2">Liquidity Pool Details</Typography>
          <ProtocolPoolSelection poolsByProtocol={poolsByProtocol} isLoading={loading} />
        </Box>
        <LiquidityProvidersPieChart data={data?.chainLiquidityProvidersByDex} isLoading={loading} />
        <WeEthExchangeRateOverTimeChart timeSpan={TimeSpan.Month} isLoadingProtocols={loading} />
        <LiquidityCompositionOverTimeChart timeSpan={TimeSpan.Month} isLoadingProtocols={loading} />
      </Box>
    </ProtocolPoolSelectionProvider>
  ) : null;
};
