import { ValueCard } from "@frontend/ui";
import { PageHeader } from "@frontend/ui/page-header";
import { useParams } from "react-router-dom";
import { PriceFeed } from "src/pages/oracle-portal/types";
import { useFeedData } from "../use-price-feed-details";
import { Endpoints } from "../../../api";
import { TimeSpanPicker } from "../../../../ccar-lending-page/components/time-span-picker";
import { PriceFeedTimeSpan, priceFeedTimeSpanOptions } from "../../../context/providers-select-context";

type Props = {
  feed?: PriceFeed;
};

const timeSpanToDisplayName: Record<PriceFeedTimeSpan, string> = {
  day: "24 hours",
  week: "7 days",
  month: "30 days",
};

const Header = ({ feed }: Props) => {
  const { feedId } = useParams();
  const { response: historicalPrice, timeSpan, setTimeSpan } = useFeedData(Endpoints.FeedPrice);

  const priceChangePercent = historicalPrice && feed ? (feed.price - historicalPrice.price) / feed.price : undefined;

  return (
    <PageHeader
      pageTitle={`${feedId!} / USD`}
      icon={feedId}
      suffixComponent={
        <ValueCard
          title="Price"
          value={feed?.price}
          delta={priceChangePercent}
          deltaTrend={Math.sign(priceChangePercent!)}
          deltaColor={Math.sign(priceChangePercent!)}
          deltaTooltip={`Change over the last ${timeSpanToDisplayName[timeSpan]}`}
          isDeltaPercent
          currency="usd"
          extra={
            <TimeSpanPicker<PriceFeedTimeSpan>
              selectedTimeSpan={timeSpan}
              onSelectTimeSpan={setTimeSpan}
              timeSpanOptions={priceFeedTimeSpanOptions}
              hideLabel
              size="small"
            />
            }
        />
        }
    />
  );
};

export default Header;
