import { Header, RenderData } from "@frontend/types";
import { Box, ChaosTable, StackedBarChart } from "@frontend/ui";
import { formatAddressCompact, formatAmount, formatStringCompact } from "@frontend/ui/utils/formatters";
import { useClientConfig } from "src/pages/ccar-lending-page/clients-config";
import { useTopHoldersQuery } from "src/pages/ccar-lending-page/generated";

const tableHeaders = (asset?: string) => {
  const headers: Header[] = [
    {
      renderType: "TEXT",
      text: "Address",
    },
    {
      renderType: "TEXT",
      text: `Balance ${asset ? `(${asset})` : ""}`,
    },
    {
      renderType: "TEXT",
      text: "Balance (USD)",
    },
    {
      renderType: "TEXT",
      text: " % Token Supply",
    },
  ];
  return headers;
};

export const TopTokenHolders = () => {
  const { stableCoin } = useClientConfig();
  const { data, loading } = useTopHoldersQuery();
  const topHolders = data?.topHolders.items || [];
  const top10Holders = topHolders.slice(0, 10);
  const tableData: RenderData[][] = topHolders.map(
    (holder) =>
      [
        {
          renderType: "TEXT",
          text: holder.addressLabel ? holder.addressLabel : formatAddressCompact(holder.address),
          value: holder.address,
          tooltipText: holder.address,
          copyText: holder.address,
        },
        {
          renderType: "TEXT",
          text: formatAmount(holder.balance),
          value: holder.balance,
        },
        {
          renderType: "TEXT",
          text: formatAmount(holder.value, { currency: "USD" }),
          value: holder.value,
        },
        {
          renderType: "TEXT",
          text: formatAmount(holder.share / 100, { isPercent: true }),
          value: holder.share,
        },
      ] as RenderData[],
  );

  return (
    <Box data-testid="top-holders-chart">
      <StackedBarChart
        chartHeight={325}
        showXAxisLine={false}
        title="Top 10 Token Holders"
        isLoading={loading}
        series={[
          {
            label: "Balance",
            data: top10Holders.map(({ addressLabel, address, balance }) => {
              if (addressLabel && addressLabel !== null) {
                return [String(addressLabel), balance];
              }
              return [address, balance];
            }),
          },
        ]}
        formatXAxisLabel={(value: string) =>
          value.startsWith("0x") ? formatAddressCompact(value, 4) : formatStringCompact(value, 14)
        }
        showLegend={false}
        currency=""
        showAllXAxisLabels
      />
      <ChaosTable
        title="Top Token Holders"
        headers={tableHeaders(stableCoin?.asset)}
        description={`Wallets with largest balances of ${stableCoin?.asset || ""}`}
        data={tableData}
        pageSize={10}
        isFullHeight
        isFilterHidden
        initialSortBy={3}
        isInitialSortDesc
        isLoading={loading}
      />
    </Box>
  );
};
