import { formatAmount } from "@frontend/ui/utils/formatters";
import { CustomSwitch, StackedBarChart } from "@frontend/ui";
import { useMemo, useState } from "react";
import { MarketPriceImpactDistribution } from "../../../../generated";

interface PriceImpactDistributionChartProps {
  marketPriceImpactDistribution?: MarketPriceImpactDistribution;
  loading: boolean;
}

export const PriceImpactDistributionChart = ({
  marketPriceImpactDistribution,
  loading,
}: PriceImpactDistributionChartProps) => {
  const [showQuantileChart, setShowQuantileChart] = useState<boolean>(false);

  const distributionChartData = useMemo(() => {
    if (loading || !marketPriceImpactDistribution) {
      return [];
    }

    const { max, min, data, numBins } = marketPriceImpactDistribution;

    const binWidth = (max - min) / numBins;

    return data.map(
      (bin, a) =>
        [
          formatAmount(Number((min + a * binWidth) / 100) || 0, {
            isPercent: true,
            notation: "compact",
          }),
          bin ?? 0,
        ] as [string, number],
    );
  }, [marketPriceImpactDistribution, loading]);

  const priceImpactQuantiles: [string, number][] = useMemo(() => {
    if (loading || !marketPriceImpactDistribution) {
      return [];
    }

    return marketPriceImpactDistribution.quantiles.map((q, index) => [
      `p${(q ?? 0) * 100}`,
      (marketPriceImpactDistribution.quantileValues?.[index] ?? 0) / 100,
    ]);
  }, [marketPriceImpactDistribution, loading]);

  return (
    <StackedBarChart
      chartHeight={325}
      showXAxisLine={false}
      description="Last 14 days"
      title={`Price Impact ${showQuantileChart ? "per Percentile" : "Distribution"}`}
      isLoading={loading}
      series={[
        {
          label: showQuantileChart ? "Price Impact" : "Count",
          data: showQuantileChart ? priceImpactQuantiles : distributionChartData,
        },
      ]}
      showLegend={false}
      currency=""
      yAxisLabel={showQuantileChart ? "Price Impact" : "Count"}
      xAxisLabel={showQuantileChart ? "Percentile" : "Price Impact"}
      isPercent={showQuantileChart}
      headerSuffix={
        <CustomSwitch
          onChange={() => setShowQuantileChart(!showQuantileChart)}
          checked={!showQuantileChart}
          checkedLabel="Distribution"
          uncheckedLabel="Percentiles"
        />
      }
    />
  );
};
