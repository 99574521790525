import { Chain, TimeSpan } from "../../generated";
import { CCARClientConfig } from "../types";

export const venusConfig: CCARClientConfig = {
  chains: [Chain.Bnb, Chain.Ethereum, Chain.Arbitrum, Chain.ZkSync, Chain.OpBnb, Chain.Base],
  riskThreshold: 1.2,
  eligibleThreshold: 1,
  isMultichain: true,
  isMultiMarket: true,
  marketType: "GeneralMarket",
  endpointsPrefix: "venus",
  overview: {
    cards: [
      "totalSupplyUsd",
      "totalBorrowUsd",
      "totalValueLockedUsd",
      "totalValueAvailableForLiquidationUsd",
      "totalValueAtRiskUsd",
      "numberOfWalletsAtRisk",
      "numberOfWalletsAvailableForLiquidation",
      "totalPotentialBadDebtUsd",
    ],
    charts: ["historyStatsTvl", "chainHistoryStats"],
    chainHistoryStats: {
      disabled: false,
    },
    chainTableConfig: {
      columns: [
        "chain",
        "totalSupplyUsd",
        "totalBorrowUsd",
        "totalValueLockedUsd",
        "totalValueAtRiskUsd",
        "activeUsers",
      ],
    },
  },
  wallets: {},
  walletDetails: {
    tables: ["totalSupplyAndBorrow", "recentEvents"],
  },
  marketDetails: {
    tables: ["wallets", "assetEvents"],
    charts: [
      "collateralAtRisk",
      "historicalPrices",
      "supplyWalletsHealth",
      "borrowWalletsHealth",
      "marketsDistribution",
      "topWallets",
    ],
    liquidationThresholdLabel: "Collateral Factor",
    priceSources: ["binance_oracle", "chainlink"],
    priceHistoryDays: 30,
  },
  chainsTab: {
    assetRecommendations: false,
  },
  liquidations: {
    showLiquidationPie: true,
    showLiquidationEvents: true,
    filterAssets: {
      marketId: "Binance-CorePool",
    },
  },
  riskExplorer: {
    liquidationThresholdLabel: "Collateral Factor",
    showAdvancedSettings: true,
  },
  alerts: {
    enabled: true,
    notificationsChannels: [
      {
        channel: "telegram",
        link: "https://t.me/+70EE7pKAv-NmODkx",
      },
    ],
  },
  stableCoin: {
    asset: "VAI",
    chain: Chain.Bnb,
    marketId: "BNB-CorePool",
    timeSpanOptions: [TimeSpan.Day, TimeSpan.Month],
    hasPools: false,
    riskTab: {
      enabled: true,
      excludedTables: ["assetEvents"],
      excludedCharts: ["borrowWalletsHealth", "supplyWalletsHealth"],
      showApyHistory: false,
    },
    beta: false,
    cards: ["price", "marketCap", "volume", "totalBorrow"],
    charts: ["collateralComposition", "assetPriceLiquidation", "marketCap", "topTokenHolders"],
    collateral: { charts: ["assetBreakdown", "assetClassBreakdown", "collateralRatio"] },
  },
};
