import { PieChart } from "@frontend/ui";
import { useTvlByProtocolQuery } from "../../../generated";

export const TvlByProtocol = () => {
  const { data, loading } = useTvlByProtocolQuery();

  return (
    <PieChart
      title="TVL by Protocol"
      series={[
        {
          data: (data?.tvlByProtocol || []).map((c) => [c.protocol, c.balanceInEth] as [string, number]) || [],
        },
      ]}
      cryptoCurrency="ETH"
      showLegendCryptoIcon
      isLoading={loading}
      shouldHideIcon={(name) => name === "Other"}
    />
  );
};
