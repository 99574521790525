import { useParams } from "react-router-dom";
import { Header, RenderData } from "@frontend/types";
import { ChaosTable, chipCell, ControlledFilters, currencyCell, iconsCell, linkCell, textCell } from "@frontend/ui";
import { getIconSrc } from "@frontend/ui/crypto-icon/get-icon-src";
import { formatAddressCompact, formatAmount } from "@frontend/ui/utils/formatters";
import { useWalletsEligibleForLiquidationData } from "./use-wallets-eligible-for-liquidation-data";
import { SortWalletsEligibleForLiquidationBy } from "../../../../generated";
import { useClientConfig } from "../../../../clients-config";
import { useMarketsContext } from "../../../markets-new/context/markets-context";
import { RouteParams } from "../../../../../../config/route-params";
import { RoutePath } from "../../../../../../config/routes";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Wallet",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Market",
    nonSortable: true,
  },
  {
    renderType: "TEXT",
    text: "Total Collateral",
    tooltip: "Total collateral in USD values",
    field: SortWalletsEligibleForLiquidationBy.TotalCollateralUsd,
  },
  {
    renderType: "ICONS",
    text: "Collateral Assets",
    nonSortable: true,
    width: "7%",
  },
  {
    renderType: "TEXT",
    text: "Total Borrow",
    tooltip: "Total borrow in USD values",
    field: SortWalletsEligibleForLiquidationBy.TotalBorrowUsd,
  },
  {
    renderType: "ICONS",
    text: "Borrowed Assets",
    nonSortable: true,
    width: "7%",
  },
  {
    renderType: "TEXT",
    text: "Health Factor",
    field: SortWalletsEligibleForLiquidationBy.HealthFactor,
  },
  {
    renderType: "TEXT",
    text: "Eligible for Liquidation",
    tooltip: "The USD value that is eligible for liquidation",
    field: SortWalletsEligibleForLiquidationBy.ValueEligibleForLiquidation,
  },
];

const WalletsEligibleForLiquidationTable = () => {
  const { clientName } = useParams();
  const { markets } = useMarketsContext();
  const { marketType } = useClientConfig();
  const { wallets, loading, filters, onFiltersChange, onPageChange, onSortChange, onSearch } =
    useWalletsEligibleForLiquidationData({});

  const tableData: RenderData[][] = wallets.map((w) => [
    linkCell(
      formatAddressCompact(w.address, 4),
      RoutePath.Risk.WalletDetails.replace(RouteParams.ClientName, clientName!).replace(RouteParams.Address, w.address),
      w.address,
    ),
    textCell(
      markets.find((m) => m.id === w.marketId)?.name || w.marketId,
      undefined,
      marketType !== "GeneralMarket" && getIconSrc(w.marketId) ? w.marketId.toLowerCase() : undefined,
    ),
    currencyCell(w.totalCollateralUsd),
    iconsCell(w.collateralAssets),
    currencyCell(w.totalBorrowUsd),
    iconsCell(w.borrowAssets),
    chipCell(
      Number(
        formatAmount(w.healthFactor, {
          notation: "compact",
        }),
      ),
      undefined,
      w.healthFactor >= 0.8 ? "warning.main" : "error.main",
      "standard",
    ),
    currencyCell(w.valueEligibleForLiquidation),
  ]);

  return (
    <ChaosTable
      title="Wallets Eligible For Liquidation"
      headers={headers}
      data={tableData}
      isLoading={loading}
      pageSize={10}
      emptyState={{
        title: "No wallets found",
        icon: "wallet-icon",
      }}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      showSearch
      serchbarPlaceholder="Search for Wallet"
      onSearch={onSearch}
      initialSortBy={7}
      isInitialSortDesc
      isFilterHidden
      customFilter={<ControlledFilters key={filters.length} filters={filters} onChange={onFiltersChange} />}
    />
  );
};

export default WalletsEligibleForLiquidationTable;
