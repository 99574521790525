import { Box, CompositionOverTimeChart, Grid } from "@frontend/ui";
import { ValueCard, ValueCardProps } from "@frontend/ui/value-card";
import { NumberChartSeries, TimeSpan } from "@frontend/ui/echarts/types";
import { useHoldersAmountQuery } from "../../../generated";
import { TimeSpanPicker } from "../../../../ccar-lending-page/components/time-span-picker";

type Props = {
  timeSpan: TimeSpan;
  onTimeSpanChange: (timeSpan: TimeSpan) => void;
};

const HoldersAmount = ({ timeSpan, onTimeSpanChange }: Props) => {
  const { loading, data } = useHoldersAmountQuery({
    variables: {
      timeSpan,
    },
  });

  const {
    numberOfWeethEethHolders,
    weEthInL2Percentage,
    weEthInDefiPercentage,
    weEthInAavePercentage,
    holdersPerChain,
    holdersPerToken,
  } = data?.holdersAmount || {};

  const cards: ValueCardProps[] = [
    { title: "Number of weETH / eETH Holders", value: numberOfWeethEethHolders },
    { title: "Percentage of weETH on L2s", value: weEthInL2Percentage, isPercent: true },
    {
      title: "Percentage of weETH in DeFi",
      value: weEthInDefiPercentage,
      isPercent: true,
      tooltip: "Aggregated across all chains",
    },
    { title: "Percentage in Aave", value: weEthInAavePercentage, isPercent: true, tooltip: "Aave V3 EtherFi market" },
  ];

  const seriesByChain = (holdersPerChain || []).reduce((acc: Record<string, NumberChartSeries>, point) => {
    point.chains.forEach((c) => {
      if (!acc[c.chain]) {
        acc[c.chain] = { label: c.chain, data: [] };
      }
      acc[c.chain].data.push([point.timestamp, c.holders]);
    });
    return acc;
  }, {});

  const seriesByToken = (holdersPerToken || []).reduce((acc: Record<string, NumberChartSeries>, point) => {
    point.tokens.forEach((t) => {
      if (!acc[t.token]) {
        acc[t.token] = { label: t.token, data: [] };
      }
      acc[t.token].data.push([point.timestamp, t.holders]);
    });
    return acc;
  }, {});

  return (
    <Box>
      <Grid container spacing={3}>
        {cards.map((card) => (
          <Grid key={card.title} item xs={12} sm={6} md={3}>
            <ValueCard {...card} />
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          float: "right",
        }}
      >
        <TimeSpanPicker
          selectedTimeSpan={timeSpan}
          onSelectTimeSpan={onTimeSpanChange}
          timeSpanOptions={[TimeSpan.Month, TimeSpan.Quarter, TimeSpan.Year]}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <CompositionOverTimeChart
            title="Holders by Chain Over Time"
            description="Includes all EtherFi tokens"
            series={Object.values(seriesByChain)}
            currency="" // Number of holders
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CompositionOverTimeChart
            title="Holders by Token Over Time"
            description="Across All Chains"
            series={Object.values(seriesByToken)}
            currency="" // Number of holders
            isLoading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HoldersAmount;
