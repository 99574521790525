import { ValueByCategoryChart } from "@frontend/ui";
import {
  AggregateAssetStatsFragment,
  useAssetTotalValueAtRiskByChainQuery,
} from "src/pages/ccar-lending-page/generated";
import { StringChartSeries } from "@frontend/ui/echarts/types";
import { formatAmount } from "@frontend/ui/utils/formatters";

type Props = {
  asset?: AggregateAssetStatsFragment;
};

export const ValueAtRisk = ({ asset }: Props) => {
  const tokenSymbol = asset?.symbol || "";
  const { data, loading } = useAssetTotalValueAtRiskByChainQuery({
    variables: {
      tokenSymbol,
    },
    skip: !asset,
  });

  const series: StringChartSeries[] = (data?.assetTotalValueAtRiskByChain || []).map((d) => ({
    id: d.chain,
    label: d.chain,
    data: d.liquidationUsdValueByPrice
      .map(({ price, liquidationAmount }) => {
        const x = price / d.currentAssetPrice - 1;

        return {
          x: Math.abs(x) < 0.0001 ? 0 : x,
          y: liquidationAmount,
        };
      })
      .sort((a, b) => (a.x > b.x ? 1 : -1))
      .map((p) => [
        formatAmount(p.x, { isPercent: true, showSign: true, maximumFractionDigits: 0, roundingMode: "halfExpand" }),
        p.y,
      ]),
  }));

  return <ValueByCategoryChart title="Value at Risk of Liquidation" series={series} isLoading={loading} />;
};
