import { useParams } from "react-router-dom";
import { Grid, ValueCard, ValueCardProps } from "@frontend/ui";
import { useOnChainLiquidityBreakdownCardsQuery } from "../../../generated";

export const ChainOverviewCards = () => {
  const { chain } = useParams();

  const { data, loading } = useOnChainLiquidityBreakdownCardsQuery({
    variables: {
      chain: chain!,
    },
  });

  const { totalLiquidityEth, dexSupplyPercent, utilization14DayMovingAverage } =
    data?.onChainLiquidityBreakdownCards || {};

  const cards: ValueCardProps[] = [
    {
      title: "weETH DEX Liquidity",
      value: loading ? undefined : totalLiquidityEth,
      cryptoCurrency: "ETH",
    },
    {
      title: "weETH DEX Liquidity to chain supply Ratio",
      value: loading ? undefined : dexSupplyPercent,
      isPercent: true,
      tooltip: "weETH DEX Liquidity as Percentage of Chain's weETH supply",
    },
    {
      title: "14d average weeth dex utilization",
      value: loading ? undefined : utilization14DayMovingAverage,
      isPercent: true,
    },
  ];

  return (
    <Grid container>
      {cards.map((card, i) => (
        <Grid key={`${card.title}${i.toString()}`} item xs={6} md={6} lg={4}>
          <ValueCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
};
