import { Header, RenderData } from "@frontend/types";
import { addressCell, ChaosTable, numberCell, textCell } from "@frontend/ui";
import { useParams } from "react-router-dom";
import { StakingTopHolder, useStakingTopHoldersQuery } from "../../../generated";
import { RouteParams, RoutePath } from "../../../../../config/routes";

const headers: Header[] = [
  {
    renderType: "TEXT",
    text: "Address",
  },
  {
    renderType: "TEXT",
    text: "eETH Exposure",
    tooltip: "Including weETH and DeFi derivatives",
  },
  {
    renderType: "TEXT",
    text: "weETH in DeFi",
    tooltip: "Cross-chain",
  },
  {
    renderType: "TEXT",
    text: "weETH on L2s",
  },
];

const TopHoldersTable = () => {
  const { clientName } = useParams();
  const { data, loading } = useStakingTopHoldersQuery();
  const topHolders: StakingTopHolder[] = data?.stakingTopHolders ?? [];

  if (!clientName) {
    return null;
  }

  const renderData: RenderData[][] = topHolders.map((s) => [
    addressCell(s.address),
    numberCell(s.eEthExposure),
    numberCell(s.weEthDefi),
    numberCell(s.weEthL2),
  ]);

  const getRowHref = (rowId: number) => {
    const topHolder = topHolders[rowId];

    if (!topHolder) {
      return "";
    }

    return RoutePath.Risk.TopHolderDetails.replace(RouteParams.ClientName, clientName).replace(
      RouteParams.TopHolderAddress,
      topHolder.address,
    );
  };

  return (
    <ChaosTable
      title="Holders"
      pageSize={10}
      isFullHeight
      headers={headers}
      data={renderData}
      isLoading={loading}
      rowHref={getRowHref}
      initialSortBy={1}
      isInitialSortDesc
      showRowChevron
    />
  );
};

export default TopHoldersTable;
